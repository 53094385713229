import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";

import MyDialog from "../MyDialog";
import { LockIcon } from "assets/icons";
import { Select, Switch } from "components";
import { DefaultStrings } from "strings";

const SettingsDialog = ({ open, config, value = "", locked }) => {
  const [updateValue, setUpdateValue] = useState(value);
  const [lockedValue, setLockedValue] = useState(!!locked);

  const {
    selectTitle,
    description,
    options,
    canEdit,
    hideLockedSwitch,
    onSelectChange,
    onSwitchChange,
  } = config.config;

  useEffect(() => {
    setUpdateValue(value);
  }, [value]);

  useEffect(() => {
    setLockedValue(!!locked);
  }, [locked]);

  return (
    <MyDialog open={open} config={config.dialog}>
      <Box style={{ color: "#323232" }} mb={2}>
        {description}
      </Box>
      <Select
        label={selectTitle}
        selected={updateValue}
        options={options}
        icon={locked && <LockIcon style={{ width: " 16px", height: "16px" }} />}
        onChange={onSelectChange}
        disabled={!canEdit || (hideLockedSwitch && locked)}
        fullWidth
      />
      {!hideLockedSwitch && (
        <Box sx={{ marginTop: "6px" }}>
          <Switch
            label={DefaultStrings.LOCKED}
            checked={lockedValue}
            onChange={onSwitchChange}
          />
        </Box>
      )}
    </MyDialog>
  );
};

export default SettingsDialog;
