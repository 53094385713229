import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  accordion: {
    boxShadow: "none",
    "&:before": {
      backgroundColor: "transparent",
    },
  },
  accordionSummary: {
    padding: 4,
    "&.Mui-expanded:hover": {
      backgroundColor: "transparent",
      borderRadius: 0,
    },
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
      borderRadius: 4,
    },
  },
  accordionDetails: {
    padding: 0,
  },
  filterButton: {
    width: "100%",
    padding: "6px 16px",
  },
  filterButtonLabel: {
    display: "block",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  filterIcon: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
  },
  selectedFilterButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  clearButton: {
    height: "36px",
  },
}));

export default useStyles;
