import React, { useState } from "react";

import MyCard from "../../MyCard";
import MyDialog from "../../../MyDialog";
import Content from "./Content";
import Spinner from "ui/Spinner";

const StatCard = ({
  icon,
  title,
  live,
  online,
  compliant,
  decommissioned,
  charts,
  canRead,
  canEdit,
  loading,
  onAction,
}) => {
  const [showDialog, setShowDialog] = useState(false);

  const configCard = {
    icon,
    title,
    onClick: () => {
      if (onAction) {
        onAction();
        setShowDialog(true);
      }
    },
  };

  const configDialog = {
    icon,
    title,
    onClose: () => {
      if (onAction) onAction();
      setShowDialog(false);
    },
    large: true,
  };
  return (
    <>
      <MyCard config={configCard} canRead={canRead} canEdit={canEdit}>
        {loading ? (
          <Spinner />
        ) : (
          <Content
            showDialog={showDialog}
            live={live}
            online={online}
            compliant={compliant}
            decommissioned={decommissioned}
            charts={charts}
          />
        )}
      </MyCard>
      <MyDialog open={showDialog} config={configDialog}>
        <Content
          showDialog={showDialog}
          live={live}
          online={online}
          compliant={compliant}
          decommissioned={decommissioned}
          charts={charts}
          showDecommissioned
          showChart
        />
      </MyDialog>
    </>
  );
};

export default StatCard;
