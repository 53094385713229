import React from "react";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";

import { MemoizedChart } from "ui/charts/HeartBeatLineChart";
import { useMobileLayout } from "hooks/uiHooks";
import StatItem from "./StatItem";
import { DeviceStrings, ProjectStrings } from "strings";
import Spinner from "ui/Spinner";
import { useStyles } from "./styles";

function Content({
  showDecommissioned = false,
  showChart,
  showDialog,
  live,
  online,
  compliant,
  decommissioned,
  charts,
}) {
  const mobile = useMobileLayout();
  const classes = useStyles();

  const chartsConverted =
    charts &&
    Object.entries(charts)
      .sort((a, b) => a[0].localeCompare(b[0]))
      .map(([k, v]) => ({ date: k, value: v }));

  const formatLabel = (value, date) => {
    return [
      value > 1
        ? `${value}${ProjectStrings.CHART_DEVICES_ONLINE}`
        : `${value}${ProjectStrings.CHART_DEVICE_ONLINE}`,
      date.split("-").reverse().join("-"),
    ];
  };

  return (
    <>
      <div
        style={{
          width: showDialog && !mobile && showChart ? "50%" : "100%",
        }}
      >
        <Box display="flex" style={{ gap: "24px" }}>
          <StatItem header={DeviceStrings.STAT_TOTAL} content={live} />
          <StatItem header={DeviceStrings.STAT_ONLINE} content={online} />
          {typeof compliant !== "undefined" && compliant !== null && (
            <StatItem
              header={DeviceStrings.STAT_COMPLIANT}
              content={compliant}
            />
          )}
          {showDecommissioned && (
            <StatItem
              header={DeviceStrings.STAT_DECOMMISSIONED}
              content={decommissioned}
            />
          )}
        </Box>
      </div>

      {showChart && !mobile && (
        <>
          <Divider />
          <div className={classes.chartContainer}>
            <span
              className="material-symbols-outlined"
              style={{ paddingRight: 16 }}
            >
              monitor_heart
            </span>
            <span>{ProjectStrings.CHART_HEARTBEATS_TITLE}</span>
          </div>
          <div
            style={{
              minHeight: "50vh",
            }}
          >
            <>
              {chartsConverted ? (
                <MemoizedChart
                  chartHeatsBeatsData={chartsConverted}
                  xAxisLabel={ProjectStrings.CHART_HEARTBEATS_X_LABEL}
                  yAxisLabel={ProjectStrings.CHART_HEARTBEATS_Y_LABEL}
                  formatLabel={formatLabel}
                />
              ) : (
                <Spinner />
              )}
            </>
          </div>
        </>
      )}
    </>
  );
}

export default Content;
