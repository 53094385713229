import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  filterDrawer: {
    padding: theme.spacing(2),
    width: "320px",
  },
  tuneIcon: {
    marginRight: theme.spacing(2),
  },
}));

export default useStyles;
