import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";

import { useProjectTags, useDefaultTags } from "services/ProjectService";
import { useDeviceTags } from "services/DeviceService";
import {
  updateDeviceTags,
  addDeviceTags,
  updateProjectTags,
} from "services/ApiService";
import { DeviceStrings } from "strings";
import { Tags } from "components";
import { DeviceTagIcon } from "assets/icons";

const DeviceTagsCard = ({
  projectId,
  deviceId,
  canRead,
  canEdit,
  variant = "button-card",
  show = false,
  setShow,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const projectTags = useProjectTags(projectId);
  const defaultTags = useDefaultTags();
  const allSuggestedTags = {
    ...((defaultTags &&
      Object.fromEntries(defaultTags.map((t) => [t.name, 0]))) ??
      {}),
    ...(projectTags ?? {}),
  };
  const deviceTags = useDeviceTags(deviceId);

  const [progress, setProgress] = useState(false);
  const [showDialog, setShowDialog] = useState(show);

  useEffect(() => {
    setShowDialog(show);
  }, [show]);

  useEffect(() => {
    if (setShow) {
      setShow(showDialog);
    }
  }, [showDialog, setShow]);

  const configCard = {
    buttonLabel: DeviceStrings.GENERAL_EDIT_TAG_BUTTON,
    fullHeight: true,
    onClick: () => setShowDialog(true),
    progress: progress,
    disableButton: !canEdit,
  };

  const configDialog = {
    inputTitle: DeviceStrings.GENERAL_EDIT_TAG_INPUT_TITLE,
    maxTagsCount: 30,
    errorMessage: DeviceStrings.GENERAL_EDIT_TAG_MAX_LIMIT,
    onModalClose: () => {
      setShowDialog(false);
    },
    onModalOk: async (itemsToUpdate, suggestedToUpdate) => {
      setShowDialog(false);
      setProgress(true);
      const setTagFunc = Array.isArray(deviceId)
        ? addDeviceTags
        : updateDeviceTags;
      try {
        await Promise.all([
          updateProjectTags({
            projectId,
            tags: Object.fromEntries(
              Object.entries(suggestedToUpdate).filter(([_, v]) => v > 0)
            ),
          }),
          setTagFunc({
            projectId,
            deviceId,
            tags: itemsToUpdate,
          }),
        ]);

        setProgress(false);
        enqueueSnackbar(DeviceStrings.TAGS_UPDATE_SUCCEED, {
          variant: "success",
        });
      } catch (e) {
        setProgress(false);
        console.debug(e);
        enqueueSnackbar(DeviceStrings.TAGS_UPDATE_ERROR, {
          variant: "error",
        });
      }
    },
  };

  const config = {
    icon: <DeviceTagIcon />,
    title: DeviceStrings.GENERAL_EDIT_TAG_TITLE,
    items: deviceTags,
    suggestedItems: allSuggestedTags,
    card: configCard,
    dialog: configDialog,
  };

  return (
    <Tags
      config={config}
      showDialog={showDialog}
      canRead={canRead}
      canEdit={canEdit}
      variant={variant}
    />
  );
};

export default DeviceTagsCard;
