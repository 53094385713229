import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  iconButton: {
    minWidth: "auto",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
    "& .MuiButton-label": {
      display: "flex",
      alignItems: "center",
    },
  },
}));
