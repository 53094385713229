import React, { useRef } from "react";
import MenuItem from "@material-ui/core/MenuItem";

import { useStyles } from "./style";
import { MoreIcon } from "assets/icons";
import { Menu } from "components";

const menuItemColors = { default: "#323232", error: "#B23A48" };

/**
 * OverflowMenu component: renders a menu with options and a position.
 * 
 * @param {Object} props - The props passed to the component, including:
 *   - name: The name of the menu.
 *   - options: An array of options for the menu, where each option is an object containing:
 *     - label: The label of the option.
 *     - onClick: The callback function to handle the option click.
 *     - color: The color of the option (default: "default").
 *     - disabled: A boolean indicating whether the option is disabled (default: false).
 *   - posX: The horizontal position of the menu (default: "right").
 *   - posY: The vertical position of the menu (default: "bottom").
 * @returns {React.ReactElement} - The OverflowMenu component.
 */
export const OverflowMenu = ({ name, options, posX = "right", posY = "bottom" }) => {
  const classes = useStyles();
  const menuRef = useRef(null);

  const onClickHandle = (action) => () => {
    menuRef.current.close();
    if (action) action();
  };

  return (
    <Menu
      ref={menuRef}
      name={`${name}-overflow-menu`}
      icon={<MoreIcon />}
      posX={posX}
      posY={posY}
      content={
        options.map((option, idx) => (
          <MenuItem
            key={option.label}
            className={classes.menuItem}
            style={{
              color:
                option.color in menuItemColors
                  ? menuItemColors[option.color]
                  : menuItemColors.default,
            }}
            onClick={onClickHandle(option.onClick)}
            disabled={option.disabled}
          >
            {option.label}
            {options.length - 1 !== idx && (
              <div className={classes.bottomBorder}></div>
            )}
          </MenuItem>
        ))
      }
    />
  );
};
