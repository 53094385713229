import React, { useMemo, useContext, useEffect } from "react";
import { matchPath, useLocation } from "react-router-dom";
import AddToQueueOutlinedIcon from "@material-ui/icons/AddToQueueOutlined";

import { OrganisationStrings, ProjectStrings, DefaultStrings } from "strings";
import {
  useAdminOrganisationTabs,
  adminOrgsTabsConfig,
} from "./userAdminOrganisationTabs";
import {
  ORGANISATION_DESC_SORT_OPTIONS,
  ORGANISATION_SORT_OPTION_KEYS,
  PROJECT_SORT_OPTION_KEYS,
  PROJECT_SORT_OPTION_FIELD,
  PROJECT_DESC_SORT_OPTIONS,
  PROJECT_BELONGING_TO_OPTIONS,
  ADMIN_PROJECTS_FILTER_OPTIONS,
  BELONGING_TO_KEYS,
  PROJECT_STATUS_OPTIONS
} from "./config";
import MainContext from "context/MainContext";
import { ROUTES } from "route";
import { GLOBAL_ACION_TYPES } from "context/globalActionTypes";
import { SortIcon, OrganisationIcon, ProjectStatusIcon } from "assets/icons";

export const useAdminOrganisationConfig = () => {
  const location = useLocation();

  const {
    sortFieldValue,
    sortDefaultFieldValue,
    sortDescValue,
    filterValue,
    onSort,
    onFilter,
    onSearch,
    onAction,
    initialSort,
    onClearAllFilter
  } = useContext(MainContext);

  const tabs = useAdminOrganisationTabs();

  // organisation sort
  const organisationSortOptions = useMemo(
    () =>
      [
        {
          title: DefaultStrings.SORT_BY,
          icon: <SortIcon />,
          items: ORGANISATION_SORT_OPTION_KEYS.map((opt) => ({
            label: OrganisationStrings[opt],
            value: opt,
            desc: opt in sortDescValue ? sortDescValue[opt] : ORGANISATION_DESC_SORT_OPTIONS[opt],
            selected: sortFieldValue ? opt === sortFieldValue : sortFieldValue,
            onClick: () => onSort(opt, ORGANISATION_DESC_SORT_OPTIONS[opt]),
          })),
          defaultSelected: sortDefaultFieldValue,
          defaultExpanded: true,
        }
      ],
    [onSort, sortDescValue, sortFieldValue, sortDefaultFieldValue]
  );

  // projects sort
  const projectSortOptions = useMemo(
    () =>
      [
        {
          title: DefaultStrings.SORT_BY,
          icon: <SortIcon />,
          items: Object.keys(PROJECT_SORT_OPTION_FIELD).map((opt) => ({
            label: ProjectStrings[opt],
            value: opt,
            desc: opt in sortDescValue ? sortDescValue[opt] : PROJECT_DESC_SORT_OPTIONS[opt],
            selected: sortFieldValue ? opt === sortFieldValue : sortFieldValue,
            onClick: () => onSort(opt, PROJECT_DESC_SORT_OPTIONS[opt]),
          })),
          defaultSelected: sortDefaultFieldValue,
        }
      ],
    [onSort, sortDescValue, sortFieldValue, sortDefaultFieldValue]
  );

  // projects selected filter value
  const selectedProjectBelongToValue = filterValue[ADMIN_PROJECTS_FILTER_OPTIONS.BELONGING_TO_ORG] || BELONGING_TO_KEYS.ALL;
  const selectedProjectStatusValue = filterValue[ADMIN_PROJECTS_FILTER_OPTIONS.STATUS] || DefaultStrings.FILTER_ALL;

  // on select project belonging to
  // if selected value is the same as the current selected value, then use default filter value "All"
  const onSelectProjectFilter = (filterKey, val, defaultValue) => {
    onFilter(filterKey, val === filterValue[filterKey] ? defaultValue : val);
  };

  const onSelectProjectBelongTo = (val) => onSelectProjectFilter(ADMIN_PROJECTS_FILTER_OPTIONS.BELONGING_TO_ORG, val, BELONGING_TO_KEYS.ALL);
  const onSelectProjectStatus = (val) => onSelectProjectFilter(ADMIN_PROJECTS_FILTER_OPTIONS.STATUS, val, DefaultStrings.FILTER_ALL);

  // projects filters
  const projectsFilterOptions = [
    {
      title: ProjectStrings.PROJECTS_FILTER_ORGANISATION_NAME,
      icon: <OrganisationIcon />,
      items: PROJECT_BELONGING_TO_OPTIONS,
      selected: selectedProjectBelongToValue,
      defaultSelected: BELONGING_TO_KEYS.ALL,
      defaultExpanded: true,
      onClick: onSelectProjectBelongTo,
    },
    {
      title: ProjectStrings.PROJECTS_FILTER_PROJECT_STATUS,
      icon: <ProjectStatusIcon />,
      items: PROJECT_STATUS_OPTIONS,
      selected: selectedProjectStatusValue,
      defaultSelected: DefaultStrings.FILTER_ALL,
      onClick: onSelectProjectStatus,
    },
  ];

  useEffect(() => {
    if (matchPath(location.pathname, { path: ROUTES.ADMIN_ORGANISATIONS, exact: true })) {
      const key = ORGANISATION_SORT_OPTION_KEYS[0];
      initialSort(key, ORGANISATION_DESC_SORT_OPTIONS[key]);
      onClearAllFilter();
    }
    if (matchPath(location.pathname, { path: ROUTES.ADMIN_PROJECTS, exact: true })) {
      const key = PROJECT_SORT_OPTION_KEYS[0];
      initialSort(key, PROJECT_DESC_SORT_OPTIONS[key]);
      onClearAllFilter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return [
    {
      path: ROUTES.ADMIN_ORGANISATIONS,
      appBar: {
        title: OrganisationStrings.ADMIN_TITLE_ORGANISATIONS,
        tabDefault: Object.keys(adminOrgsTabsConfig)[0],
        tabs,
        sort: organisationSortOptions,
        onSearch: (value) =>
          value && value.length > 0 ? onSearch(value) : onSearch(null),
      },
      actionButton: {
        actions: [
          {
            icon: <AddToQueueOutlinedIcon />,
            name: OrganisationStrings.ADD_DIALOG_CREATE,
            onClick: () =>
              onAction(GLOBAL_ACION_TYPES.ORGANISATION_CREATE, true),
          },
        ],
      },
    },
    {
      path: ROUTES.ADMIN_PROJECTS,
      appBar: {
        title: OrganisationStrings.ADMIN_TITLE_PROJECTS,
        tabDefault: Object.keys(adminOrgsTabsConfig)[1],
        tabs,
        sort: projectSortOptions,
        filter: projectsFilterOptions,
        onSearch: (value) =>
          value && value.length > 0 ? onSearch(value) : onSearch(null),
      },
    },
  ];
};
