import React, { useEffect, useState } from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Chip,
  IconButton,
  ListSubheader,
  Menu,
  MenuItem,
  Tooltip,
  Avatar,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import RestrictedContent from "../RestrictedContent";
import MoreIcon from "@material-ui/icons/MoreVert";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { InfoIcon } from "assets/icons";

import { DefaultStrings } from "../../strings";
import LinearSpinner from "ui/LinearSpinner";

/*
 sample config
 */
// const config = {
//   icon: <DevicesOutlinedIcon />,
//   info: "Information tooltip",
//   title: "Note",
//   onClick: () => {},
//   // in order to use overflowId, the parent container must have maxHeight set
//   overflowId: "uniqueid",
//   progress: true,
//   tags: ["Tag1", "Tag2"],
//   overflow: [
//     {
//       label: "Label",
//       onClick: () => {},
//       disabled: false,
//     }
//   ],
//   showDrag: true,
// };

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
  title: {
    marginRight: theme.spacing(1),
    fontSize: "1rem",
    fontWeight: 700,
  },
  action: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
  content: ({ canEdit }) => ({
    flexGrow: 1,
    color: "#747474",
    paddingTop: 0,
    overflow: canEdit ? "hidden" : "auto",
  }),
  info: {
    color: "#777777",
    width: "16px",
    height: "16px",
    margin: "16px",
  },
  bottomBar: ({ canEdit }) => ({
    position: "absolute",
    width: "100%",
    bottom: 0,
    height: theme.spacing(4.5),
    paddingTop: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    backgroundColor: canEdit ? "#FFFFFF" : theme.palette.background.default,
    color: "#5dbcd2",
    zIndex: 1,
  }),
  icon: {
    height: "40px",
    width: "40px",
  },
  avatar: {
    "&.MuiAvatar-root": {
      backgroundColor: "transparent",
      "& svg": {
        color: "#323232",
        width: "24px",
        height: "24px",
      },
    },
  },
  dragBox: {
    padding: 4,
    cursor: "move",
    display: "flex",
    alignItems: "center",
  },
}));

const OVERFLOW_PREFIX = "myCard-";

// canRead must be given
// canRead = undefined => loading spinner
// canRead = false => restricted content
// canRead = true => normal content
// canEdit = false => not clickable and transparent background
// canEdit = true => clickable and white background
const MyCard = ({ children, config, canRead, canEdit, highlight, style }) => {
  const classes = useStyles({ canEdit, highlight });
  const [showMore, setShowMore] = useState();
  const [anchor, setAnchor] = useState(null);
  const theme = useTheme();

  const InfoAction = ({ info }) => (
    <Tooltip title={info} arrow>
      <InfoIcon className={classes.info} />
    </Tooltip>
  );

  const icon = config?.icon;
  const info = config?.info && <InfoAction info={config.info} />;
  const title = config?.title;
  const onClick = config?.onClick;
  const overflowId = config?.overflowId;
  const progress = config?.progress;
  const tags = config?.tags;
  const overflow = config?.overflow;
  const showDrag = config?.showDrag;

  const progressContent = progress && <LinearSpinner />;
  const contentId = overflowId && `${OVERFLOW_PREFIX}${overflowId}`;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (onClick && overflowId) {
      const contentElem = document.getElementById(contentId);
      const overflowElem = document.getElementById(overflowId);
      if (overflowElem) {
        const overflown = contentElem.clientHeight < overflowElem.clientHeight;
        setShowMore(overflown);
      }
    }
  });

  const onClickMenu = (cb) => {
    setAnchor(null);
    cb();
  };

  const overflowContent = overflow && overflow.length > 0 && (
    <>
      <IconButton
        classes={{ root: classes.icon }}
        color="inherit"
        onClick={(e) => setAnchor(e.currentTarget)}
      >
        <MoreIcon />
      </IconButton>
      <Menu
        id="menuOverflow"
        anchorEl={anchor}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={anchor !== null}
        onClose={(e) => setAnchor(null)}
      >
        {overflow.map((m) =>
          m.onClick ? (
            <MenuItem
              key={`menu-${m.label}`}
              onClick={() => onClickMenu(m.onClick)}
              disabled={m.disabled}
            >
              {m.label}
            </MenuItem>
          ) : (
            <ListSubheader key={`menu-${m.label}`}>{m.label}</ListSubheader>
          )
        )}
      </Menu>
    </>
  );

  const header = (
    <CardHeader
      title={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span className={classes.title}>{title}</span>
          {tags && (
            <div style={{ flexGrow: 1 }}>
              {tags.map((t) => (
                <Chip key={`chip-${t}`} label={t} style={{ marginLeft: 4 }} />
              ))}
            </div>
          )}
          {overflowContent}
        </div>
      }
      action={info}
      avatar={<Avatar className={classes.avatar}>{icon}</Avatar>}
      titleTypographyProps={{ variant: "caption" }}
    />
  );
  const content = (
    <CardContent
      id={contentId}
      className={classes.content}
      style={{
        ...(!children && { paddingBottom: 0 }),
      }}
    >
      {children}
    </CardContent>
  );
  const more = showMore && (
    <div className={classes.bottomBar}>{DefaultStrings.CARD_SHOW_MORE}</div>
  );
  const wholeCardContent = (
    <div
      style={{
        display: "flex",
        alignItems: "stretch",
        height: "100%",
      }}
    >
      {showDrag && (
        <div className={classes.dragBox}>
          <DragIndicatorIcon color="primary" />
        </div>
      )}
      <div
        style={{
          flexGrow: 1,
        }}
      >
        {header}
        <RestrictedContent permitted={canRead} text={false}>
          {content}
          {more}
        </RestrictedContent>
      </div>
    </div>
  );
  const action =
    canRead && canEdit ? (
      <CardActionArea className={classes.action} onClick={onClick}>
        {wholeCardContent}
      </CardActionArea>
    ) : (
      <>{wholeCardContent}</>
    );

  return (
    <Card
      className={classes.root}
      style={{
        backgroundColor: highlight
          ? theme.palette.primary.main
          : canEdit
          ? "#FFFFFF"
          : theme.palette.background.default,
        ...style,
      }}
    >
      {progressContent}
      {action}
    </Card>
  );
};

export default MyCard;
