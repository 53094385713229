import React from "react";
import Button from "@material-ui/core/Button";
import { useSnackbar } from "notistack";

import { useConfirm } from "hooks/uiHooks";
import { ccOnline, ccCompliance, ccDeploy } from "services/ApiService";
import { getProjectQRSetup } from "services/ProjectService";
import { getLinkUrl } from "services/MediaService";
import { DefaultStrings, DeviceStrings } from "strings";
import { useStyles } from "./styles";
import { DEVICE_PLATFORMS } from "services/DeviceService";

export const ActionsContent = (props) => {
  const {
    projectId,
    selectedDeviceIds,
    actionsDisabled,
    canWriteDevices,
    canWriteDeviceExtra,
    selectedDevicePlatforms,
    selectedCount,
    selectedDevices,
    onOpenCustomQR,
    onOpenTagDialog,
    onOpenTransferDialog,
  } = props;
  const classes = useStyles();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  
  const onMultiOnlineHandle = () => {
    const message = DeviceStrings.MULTI_ONLINE_CONFIRM.replace(
      "{x}",
      selectedCount
    );
    confirm({ message })
      .then(() => {
        ccOnline({
          projectId,
          deviceId: selectedDeviceIds,
        }).then((result) => {
          if (result.success) {
            enqueueSnackbar(DeviceStrings.REQUEST_SENT, {
              variant: "success",
            });
          } else {
            enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
            console.warn("ccOnline", result.errors[0]);
          }
        });
      })
      .catch(() => {});
  };

  const onMultiComplianceHandle = () => {
    const message = DeviceStrings.MULTI_COMPLIANCE_CONFIRM.replace(
      "{x}",
      selectedCount
    );
    confirm({ message })
      .then(() => {
        ccCompliance({
          projectId,
          deviceId: selectedDeviceIds,
        }).then((result) => {
          if (result.success) {
            enqueueSnackbar(DeviceStrings.REQUEST_SENT, {
              variant: "success",
            });
          } else {
            enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
            console.warn("ccCompliance", result.errors[0]);
          }
        });
      })
      .catch(() => {});
  };

  const onMultiDeployHandle = () => {
    const message = DeviceStrings.MULTI_DEPLOY_CONFIRM.replace(
      "{x}",
      selectedCount
    );
    confirm({ message })
      .then(() => {
        ccDeploy({
          projectId,
          deviceId: selectedDeviceIds,
        }).then((result) => {
          if (result.success) {
            enqueueSnackbar(DeviceStrings.REQUEST_SENT, {
              variant: "success",
            });
          } else {
            enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
            console.warn("ccDeploy", result.errors[0]);
          }
        });
      })
      .catch(() => {});
  };

  const onOpenCustomQRHandle = async () => {
    const options = Object.values(selectedDevices)
      .map((device) => {
        const { deviceId } = device;
        const isQrId = device?.platform === DEVICE_PLATFORMS.QRID;

        if (!isQrId) return null;

        return {
          url: getLinkUrl({ linkId: deviceId }),
          filename: deviceId,
        };
      })
      .filter((d) => d);

    if (!options.length) {
      enqueueSnackbar(DeviceStrings.CREATE_SMART_TAGS_NO_SMART_TAGS, {
        variant: "warning",
      });
      return;
    }
    const qrSetup = await getProjectQRSetup(projectId);

    onOpenCustomQR({ options, qrSetup })
  };

  return (
    <div className={classes.multiRow}>
      <Button
        className={classes.multiButton}
        disabled={
          actionsDisabled ||
          !canWriteDevices ||
          selectedDevicePlatforms.includes(DEVICE_PLATFORMS.QRID)
        }
        onClick={onMultiOnlineHandle}
      >
        {DeviceStrings.ONLINE_BUTTON}
      </Button>
      <Button
        className={classes.multiButton}
        disabled={
          actionsDisabled ||
          !canWriteDevices ||
          selectedDevicePlatforms.includes(DEVICE_PLATFORMS.QRID)
        }
        onClick={onMultiComplianceHandle}
      >
        {DeviceStrings.COMPLIANCE_BUTTON}
      </Button>
      <Button
        className={classes.multiButton}
        disabled={
          actionsDisabled ||
          !canWriteDevices ||
          selectedDevicePlatforms.includes(DEVICE_PLATFORMS.QRID)
        }
        onClick={onMultiDeployHandle}
      >
        {DeviceStrings.DEPLOY_BUTTON}
      </Button>
      <Button
        className={classes.multiButton}
        disabled={actionsDisabled || !canWriteDeviceExtra}
        onClick={onOpenTagDialog}
      >
        {DeviceStrings.TAG_BUTTON}
      </Button>
      <Button
        className={classes.multiButton}
        disabled={actionsDisabled || !canWriteDevices}
        onClick={onOpenTransferDialog}
      >
        {DeviceStrings.TRANSFER_BUTTON}
      </Button>
      <Button
        className={classes.multiButton}
        disabled={actionsDisabled || !canWriteDeviceExtra}
        onClick={onOpenCustomQRHandle}
      >
        {DeviceStrings.CREATE_SMART_TAGS_BUTTON}
      </Button>
    </div>
  )
};
