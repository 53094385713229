import React, { useRef } from "react";
import MoreIcon from "@material-ui/icons/MoreVert";
import ListSubheader from "@material-ui/core/ListSubheader";
import MenuItem from "@material-ui/core/MenuItem";

import { useMobileLayout } from "hooks/uiHooks";
import { MenuItemDelete } from "./MenuItemDelete";
import { TeamCodeStrings } from "strings";
import { Menu as OverflowMenu } from "components";

export const Menu = (props) => {
  const { info, canEdit, onCopyCode, onCopyLink, onDelete } = props;

  const mobile = useMobileLayout();
  const menuRef = useRef(null);

  const onCopyCodeHandle = () => {
    menuRef.current.close();
    onCopyCode();
  };
  const onCopyLinkHandle = () => {
    menuRef.current.close();
    onCopyLink();
  };
  const onDeleteHandle = () => {
    menuRef.current.close();
    onDelete();
  };

  return (
    <OverflowMenu
      ref={menuRef}
      name="team-share-code-card"
      icon={<MoreIcon />}
      posX="right"
      posY="bottom"
      content={
        <>
          {mobile && (
            <div>
              <ListSubheader>
                {TeamCodeStrings.TEAM_CODE_CARD_CAPTION_COPY}
              </ListSubheader>
              <MenuItem onClick={onCopyCodeHandle}>
                {TeamCodeStrings.TEAM_CODE_CARD_COPY_CODE}
              </MenuItem>
              <MenuItem onClick={onCopyLinkHandle}>
                {TeamCodeStrings.TEAM_CODE_CARD_COPY_LINK}
              </MenuItem>
              <ListSubheader>
                {TeamCodeStrings.TEAM_CODE_CARD_CAPTION_MANAGE}
              </ListSubheader>
            </div>
          )}
          <MenuItemDelete
            info={info}
            canEdit={canEdit}
            onDelete={onDeleteHandle}
          />
        </>
      }
    />
  );
};
