import React, {
  useEffect,
  useRef,
  useState,
  useContext,
  useCallback,
} from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

import { useConfirm } from "hooks/uiHooks";
import { OrganisationCard } from "ui/cards/organisationCard/OrganisationCard";
import { useStyles } from "./style";
import {
  useUserOrganisationPermissions,
  useGetOrganisationStats,
  leaveOrganisation as leaveOrganisationApi,
  deleteOrganisation as deleteOrganisationApi,
} from "services/OrganisationService";
import OrganisationDeviceStatCard from "ui/cards/deviceStatCard/OrganisationDeviceStatCard";
import OrganisationSmartTagsStatCard from "ui/cards/deviceStatCard/OrganisationSmartTagsStatCard";
import TeamStatCard from "ui/cards/teamStatCard/TeamStatCard";
import ProjectsStatCard from "ui/cards/projectsStatCard/ProjectsStatCard";
import GuestsStatCard from "ui/cards/guestsStatCard/GuestsStatCard";
import { useBreakpoints } from "services/UiService";
import { OrganisationDialog } from "ui/dialogs";
import { OrganisationStrings, DefaultStrings } from "strings";
import MainContext from "context/MainContext";
import { GLOBAL_ACION_TYPES } from "context/globalActionTypes";
import { ROUTES } from "route";

const Dashboard = ({ userId }) => {
  const { organisationId } = useParams();
  const confirm = useConfirm();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { actionType, onAction } = useContext(MainContext);

  const mounted = useRef(false);

  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const { isLargeDesktop, isTablet, isMobile } = useBreakpoints();

  const classes = useStyles({ isMobile, isTablet, isLargeDesktop });
  useGetOrganisationStats(mounted.current && organisationId);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  // permissions control
  const {
    canReadOrganisation,
    canWriteOrganisation,
    canReadDevices,
    canReadUsers,
    canReadProjects,
    canWriteProjects
  } = useUserOrganisationPermissions({
    userId,
    organisationId,
  });

  const onCompleteHandle = () => {
    setOpenCreateDialog(false);
    setLoading(false);
  };
  const onRequestHandle = () => setLoading(true);
  const onRequestFailedHandle = () => setLoading(false);
  const onOpenDialogHandle = () => setOpenCreateDialog(true);
  const onCloseDialogHandle = () => setOpenCreateDialog(false);

  const leaveOrganisation = useCallback(() => {
    confirm({
        message: OrganisationStrings.OVERVIEW_LEAVE_CONFIRM,
    }).then(async ({ onProgress }) => {
      onProgress(true);
      try {
        // leave organisation and show toast
        await leaveOrganisationApi({ organisationId });
        onAction(GLOBAL_ACION_TYPES.ORGANISATION_LEAVE, false);
        onProgress(false);
        enqueueSnackbar(OrganisationStrings.OVERVIEW_LEFT, {
          variant: "success",
        });
      } catch (error) {
        console.error(error);
        onProgress(false);
        onAction(GLOBAL_ACION_TYPES.ORGANISATION_LEAVE, false);
        enqueueSnackbar(DefaultStrings.ERROR_MSG, {
          variant: "error",
          });
        }
      })
      .catch(() => {
        onAction(GLOBAL_ACION_TYPES.ORGANISATION_LEAVE, false);
      });
  }, [confirm, enqueueSnackbar, onAction, organisationId]);

  const deleteOrganisation = useCallback(() => {
    confirm({
      message: OrganisationStrings.OVERVIEW_DELETE_CONFIRM,
    })
      .then(async ({ onProgress }) => {
        onProgress(true);
        try {
          // delete organisation and show toast
          await deleteOrganisationApi(organisationId);
          onAction(GLOBAL_ACION_TYPES.ORGANISATION_DELETE, false);
          onProgress(false);
          // redirect to organisations page
          history.push(ROUTES.ADMIN_ORGANISATIONS);
          enqueueSnackbar(OrganisationStrings.OVERVIEW_DELETED, {
            variant: "success",
          });
        } catch (error) {
          console.error(error);
          onAction(GLOBAL_ACION_TYPES.ORGANISATION_DELETE, false);
          onProgress(false);
          enqueueSnackbar(DefaultStrings.ERROR_MSG, {
            variant: "error",
          });
        }
      })
      .catch(() => {
        onAction(GLOBAL_ACION_TYPES.ORGANISATION_DELETE, false);
      });
  }, [confirm, enqueueSnackbar, history, organisationId, onAction]);

  useEffect(() => {
    if (actionType[GLOBAL_ACION_TYPES.ORGANISATION_LEAVE]) {
      leaveOrganisation();
    }
    if (actionType[GLOBAL_ACION_TYPES.ORGANISATION_DELETE]) {
      deleteOrganisation();
    }
  }, [actionType, deleteOrganisation, leaveOrganisation]);

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.infoContainer}>
          <OrganisationCard
            organisationId={organisationId}
            loading={loading}
            onClick={canWriteOrganisation && onOpenDialogHandle}
          />
        </div>
        <div className={classes.statsWrapper}>
          <div className={classes.cardWrapper}>
            <OrganisationDeviceStatCard
              organisationId={organisationId}
              canRead={canReadDevices}
              canEdit={canWriteProjects}
            />
          </div>
          <div className={classes.cardWrapper}>
            <OrganisationSmartTagsStatCard
              organisationId={organisationId}
              canRead={canReadDevices}
              canEdit={canWriteProjects}
            />
          </div>
          <div className={classes.cardWrapper}>
            <TeamStatCard
              organisationId={organisationId}
              canRead={canReadUsers}
            />
          </div>
          <div className={classes.cardWrapper}>
            <GuestsStatCard
              organisationId={organisationId}
              canRead={canReadOrganisation}
            />
          </div>
          <div className={classes.cardWrapper} style={{ flexBasis: "60%" }}>
            <ProjectsStatCard
              organisationId={organisationId}
              canRead={canReadProjects}
            />
          </div>
        </div>
      </div>
      <OrganisationDialog
        organisationId={organisationId}
        open={openCreateDialog}
        onComplete={onCompleteHandle}
        onRequest={onRequestHandle}
        onRequestFailed={onRequestFailedHandle}
        onClose={onCloseDialogHandle}
      />
    </div>
  );
};

export default Dashboard;
