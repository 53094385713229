import React, { useMemo, useContext, useEffect } from "react";
import { matchPath, useLocation } from "react-router-dom";

import { OrganisationStrings, ProjectStrings } from "strings";
import { useCurrentOrganisation } from "services/UserService";
import {
  useUserOrganisationPermissions,
  useUserHasConnectedDomain,
} from "services/OrganisationService";
import { useTeamOrganisationPermissionsOptions } from "services/PermissionService";
import MainContext from "context/MainContext";
import { GLOBAL_ACION_TYPES } from "context/globalActionTypes";
import { ROUTES } from "route";
import {
  PROJECT_SORT_OPTION_KEYS,
  PROJECT_DESC_SORT_OPTIONS,
  TEAM_SORT_OPTION_KEYS,
  TEAM_DESC_SORT_OPTIONS,
  GUEST_SORT_OPTION_KEYS,
  ORG_TEAM_FILTER_OPTIONS,
} from "./config";
import { DefaultStrings } from "strings";
import { SortIcon, UserRoleIcon } from "assets/icons";

export const useOrganisationActions = ({ organisationId, userId }) => {
  const location = useLocation();

  const {
    sortFieldValue,
    filterValue,
    sortDefaultFieldValue,
    sortDescValue,
    onSort,
    onAction,
    initialSort,
    onFilter,
    onClearAllFilter,
  } = useContext(MainContext);

  const selectedOrganisationId = useCurrentOrganisation();
  const userHasConnectedDomains = useUserHasConnectedDomain(organisationId);
  const {
    canDeleteOrganisation,
    canWriteOrganisation,
    isSuperAdmin,
    isUserAdmin,
  } = useUserOrganisationPermissions({
    userId,
    organisationId,
  });

  const teamPermissionsOptions = useTeamOrganisationPermissionsOptions();

  const userRolesOptions = useMemo(() => {
    const allUsers = {
      title: DefaultStrings.FILTER_ALL,
      value: DefaultStrings.FILTER_ALL,
    };
    if (teamPermissionsOptions) {
      const perms = [...teamPermissionsOptions];
      perms.unshift(allUsers);
      return perms;
    }
    return teamPermissionsOptions;
  }, [teamPermissionsOptions]);

  const leaveOrganisation = {
    label: OrganisationStrings.OVERVIEW_MENU_LEAVE,
    onClick: () => onAction(GLOBAL_ACION_TYPES.ORGANISATION_LEAVE, true),
    disabled:
      (selectedOrganisationId === organisationId &&
        (userHasConnectedDomains === true || isUserAdmin === true)) ||
      (selectedOrganisationId !== organisationId && isSuperAdmin === true),
  };

  const deleteOrganisation = {
    label: OrganisationStrings.OVERVIEW_MENU_DELETE,
    onClick: () => onAction(GLOBAL_ACION_TYPES.ORGANISATION_DELETE, true),
    disabled: !canDeleteOrganisation,
    color: "error",
  };

  const inviteToOrganisation = {
    label: OrganisationStrings.OVERVIEW_MENU_INVITE,
    onClick: () => onAction(GLOBAL_ACION_TYPES.ORGANISATION_INVITE, true),
    disabled: !canWriteOrganisation,
  };

  const orgTeamSortOptions = useMemo(
    () =>
      [
        {
          title: DefaultStrings.SORT_BY,
          icon: <SortIcon />,
          items: TEAM_SORT_OPTION_KEYS.map((opt) => ({
            label: OrganisationStrings[opt],
            value: opt,
            desc: opt in sortDescValue ? sortDescValue[opt] : TEAM_DESC_SORT_OPTIONS[opt],
            selected: sortFieldValue ? opt === sortFieldValue : sortFieldValue,
            onClick: () => onSort(opt, TEAM_DESC_SORT_OPTIONS[opt]),
          })),
          defaultSelected: sortDefaultFieldValue,
        },
      ],
    [onSort, sortDescValue, sortFieldValue, sortDefaultFieldValue]
  );

  const orgGuestSortOptions = useMemo(
    () =>
      [
        {
          title: DefaultStrings.SORT_BY,
          icon: <SortIcon />,
          items: GUEST_SORT_OPTION_KEYS.map((opt) => ({
            label: OrganisationStrings[opt],
            value: opt,
            desc: opt in sortDescValue ? sortDescValue[opt] : TEAM_DESC_SORT_OPTIONS[opt],
            selected: sortFieldValue ? opt === sortFieldValue : sortFieldValue,
            onClick: () => onSort(opt, TEAM_DESC_SORT_OPTIONS[opt]),
          })),
          defaultSelected: sortDefaultFieldValue,
          defaultExpanded: true,
        },
      ],
    [onSort, sortDescValue, sortFieldValue, sortDefaultFieldValue]
  );

  const projectSortOptions = useMemo(
    () =>
      [
        {
          title: DefaultStrings.SORT_BY,
          icon: <SortIcon />,
          items: PROJECT_SORT_OPTION_KEYS.map((opt) => ({
            label: ProjectStrings[opt],
            value: opt,
            desc: opt in sortDescValue ? sortDescValue[opt] : PROJECT_DESC_SORT_OPTIONS[opt],
            selected: sortFieldValue ? opt === sortFieldValue : sortFieldValue,
            onClick: () => onSort(opt, PROJECT_DESC_SORT_OPTIONS[opt]),
          })),
          defaultSelected: sortDefaultFieldValue,
          defaultExpanded: true,
        },
      ],
    [onSort, sortDescValue, sortFieldValue, sortDefaultFieldValue]
  );

  const selectedTeamRoleValue = filterValue[ORG_TEAM_FILTER_OPTIONS.ROLE] || DefaultStrings.FILTER_ALL;

  const onSelectTeamRole = (val) => {
    if (val === selectedTeamRoleValue) {
      onFilter(ORG_TEAM_FILTER_OPTIONS.ROLE, DefaultStrings.FILTER_ALL);
    } else {
      onFilter(ORG_TEAM_FILTER_OPTIONS.ROLE, val);
    }
  };

  const orgTeamFilterOptions = [
    {
      title: OrganisationStrings.SORT_OPTION_ROLE,
      icon: <UserRoleIcon />,
      items: userRolesOptions,
      selected: selectedTeamRoleValue,
      defaultSelected: DefaultStrings.FILTER_ALL,
      defaultExpanded: true,
      onClick: onSelectTeamRole,
    },
  ];

  useEffect(() => {
    if (matchPath(location.pathname, { path: ROUTES.ORGANISATION_PROJECTS, exact: true })) {
      const key = PROJECT_SORT_OPTION_KEYS[0];
      initialSort(key, PROJECT_DESC_SORT_OPTIONS[key]);
      onClearAllFilter();
    }
    if (matchPath(location.pathname, { path: ROUTES.ORGANISATION_TEAM, exact: true })) {
      const key = TEAM_SORT_OPTION_KEYS[0];
      initialSort(key, TEAM_DESC_SORT_OPTIONS[key]);
      onClearAllFilter();
    }
    if (
      matchPath(location.pathname, { path: ROUTES.ORGANISATION_GUESTS, exact: true })
    ) {
      const key = GUEST_SORT_OPTION_KEYS[0];
      initialSort(key, TEAM_DESC_SORT_OPTIONS[key]);
      onClearAllFilter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return {
    leaveOrganisation,
    deleteOrganisation,
    inviteToOrganisation,
    orgTeamSortOptions,
    orgGuestSortOptions,
    projectSortOptions,
    orgTeamFilterOptions,
  };
};
