import React, { useMemo } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import clsx from "clsx";

import { CheckIcon } from "assets/icons";
import useStyles from "./styles";

/**
 * Filter item component for the filters drawer.
 * 
 * @param {Object} props - Component properties.
 * @param {Object} props.item - Filter item data.
 * @param {Array} props.items - List of filter items.
 * @param {Array} props.selected - List of selected filter values.
 * @param {string} props.defaultSelected - Default selected filter value.
 * @param {function} props.onClick - Function to handle filter click.
 */
export const FilterItem = (props) => {
  const { item, items, selected, defaultSelected, onClick } = props;

  const classes = useStyles();
  const itemIndex = useMemo(() =>
    items.findIndex(i => i.value === item.value),
    [items, item]
  );
  const isSingleItem = useMemo(() => items.length === 1, [items]);
  const isLastItemInRow = useMemo(() =>
    (itemIndex % 2 === 0) && (items.length % 2 === 1) && (itemIndex === items.length - 1),
    [itemIndex, items]
  );
  const isSelected = useMemo(() => {
    if (Array.isArray(selected)) {
      return selected.includes(item.value)
    }
    return selected === item.value
  }, [selected, item]);

  // Handle click on filter
  const onClickHandle = () => {
    if (onClick) onClick(item.value);
  };
  
  return (
    <Box style={{ width: isSingleItem || isLastItemInRow ? "100%" : "50%" }} p={0.5}>
      <Tooltip title={item.title} placement="top" arrow>
        <Button
          classes={{
            root: clsx(
              classes.filterButton,
              (isSelected && item.value !== defaultSelected) && classes.selectedFilterButton,
            )
          }}
          variant="outlined"
          color="primary"
          startIcon={isSelected ? <CheckIcon /> : null}
          disabled={item.disabled}
          onClick={onClickHandle}
        >
          <div className={classes.filterButtonLabel}>{item.title}</div>
        </Button>
      </Tooltip>
    </Box>
  );
};
