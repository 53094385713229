import React, { createContext, useMemo, useState, useCallback, useEffect } from "react";
import { debounce } from "services/UiService";
import { initValues } from "./initValues";
import { useLocation } from "react-router-dom";

const MainContext = createContext(initValues);

/**
 * The MainContextProvider component is responsible for providing the main context for the application.
 * It wraps the child components and makes the context available to them.
 * 
 * @param {Object} props - The props passed to the component, including:
 *   - children: The child components that will be wrapped by the MainContextProvider and have access to the context.
 */
export const MainContextProvider = ({ children }) => {
  const location = useLocation();

  const [sortFieldValue, setSortFieldValue] = useState(null);
  const [sortDefaultFieldValue, setSortDefaultFieldValue] = useState(null);
  const [sortDescValue, setSortDescValue] = useState({});
  const [sortDefaultDescValue, setSortDefaultDescValue] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const [filterValue, setFilterValue] = useState({});
  const [actionType, setActionType] = useState(initValues.actionType);
  const [filterItems, setFilterItems] = useState({});

  useEffect(() => {
    setSearchValue(null);
  }, [location.pathname]);
  
  // This function is responsible for handling the sorting of data.
  const onSortHandle = useCallback(
    (opt, defaultDesc) => {      
      if (sortFieldValue === opt || (sortFieldValue === null && opt === sortDefaultFieldValue)) {
        // toggle the sortDescValue for the existing option or set the sortDescValue for the new option
        setSortDescValue((prevState) => ({ ...prevState, [opt]: !prevState[opt] }));
      }

      if (!(opt in sortDescValue)) {
        // set the sortDescValue for the new option
        setSortDescValue((prevState) => ({ ...prevState, [opt]: defaultDesc }));
      } 
      // set the sort field value
      setSortFieldValue(opt);
    },
    [sortDescValue, sortFieldValue, sortDefaultFieldValue]
  );

  // This function is responsible for initializing the sort field value 
  //and sort order for a given option.
  const initialSortHandle = useCallback((opt, defaultDesc) => {
    // reset the sort field value
    setSortFieldValue(null);
    // set the sort order
    setSortDescValue(({ [opt]: defaultDesc }));
    // set default value for the sort value
    setSortDefaultFieldValue(opt);
    // set default value for sort order
    setSortDefaultDescValue([opt, defaultDesc]);
  }, []);

  // This function is responsible for debouncing the search value.
  const onSearchHandle = debounce((value) => {
    if (searchValue === value) return;
      setSearchValue(value);
    }, 300);

  // This function is responsible for updating the filter value for a given key.
  const onFilterHandle = useCallback((key, value) => {
    setFilterValue((prevState) => ({ ...prevState, [key]: value }));
  }, []);

  // This function is responsible for initializing the filter items for a given key.
  const onInintFilterItemsHandle = useCallback((key, items) => {
    setFilterItems((prevState) => ({ ...prevState, [key]: items }));
  }, []);

  // This function is responsible for clearing all filter values 
  // and resetting the sort field value and sort order
  const onClearAllFilterHandle = useCallback(() => {
    setSearchValue(null);
    setFilterValue({}); 
    setSortFieldValue(null);
    // reset the sort order
    if (sortDefaultDescValue) {
      const [opt, defaultDesc] = sortDefaultDescValue;  
      setSortDescValue({ [opt]: defaultDesc });
    } else {
      setSortDescValue({});
    }
  }, [sortDefaultDescValue]);

  // This function is responsible for handling various types of actions within the application.
  // The actions can be related to the creation, deletion, or management of different entities.
  const onActionHandle = useCallback(
    (key, action) => {
      setActionType({ ...actionType, [key]: action });
    },
    [actionType]
  );

  const contextValues = useMemo(
    () => ({
      sortFieldValue,
      sortDefaultFieldValue,
      sortDescValue,
      searchValue,
      actionType,
      filterValue,
      filterItems,
      onSort: onSortHandle,
      initialSort: initialSortHandle,
      onSearch: onSearchHandle,
      onFilter: onFilterHandle,
      onInintFilterItems: onInintFilterItemsHandle,
      onClearAllFilter: onClearAllFilterHandle,
      onAction: onActionHandle,
    }),
    [
      sortFieldValue,
      sortDefaultFieldValue,
      sortDescValue,
      searchValue,
      actionType,
      filterValue,
      filterItems,
      onSortHandle,
      initialSortHandle,
      onSearchHandle,
      onFilterHandle,
      onInintFilterItemsHandle,
      onClearAllFilterHandle,
      onActionHandle,
    ]
  );

  return <MainContext.Provider value={contextValues}>{children}</MainContext.Provider>;
};

export default MainContext;
