import { DefaultStrings, DeviceStrings, ProjectStrings } from "strings";

export const PROJECT_STATUS = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
  DEVELOPMENT: "Development",
};

export const ORG_TABS_KEYS = {
  DASHBOARD: "DASHBOARD",
  PROJECTS: "PROJECTS",
  TEAM: "TEAM",
  GUESTS: "GUESTS",
  ACTIVITY: "ACTIVITY",
  SETTINGS: "SETTINGS",
};

/** Organisation sort options */
export const ORGANISATION_SORT_OPTION_FIELD = {
  SORT_OPTION_MOSTRECENT: "modifiedDate",
  SORT_OPTION_NAME: "name",
};

// default sort options, e.g. descending or ascending
// descending is true, ascending is false
export const ORGANISATION_DESC_SORT_OPTIONS = {
  [Object.keys(ORGANISATION_SORT_OPTION_FIELD)[0]]: true, // most recent
  [Object.keys(ORGANISATION_SORT_OPTION_FIELD)[1]]: false, // name
};

export const ORGANISATION_SORT_OPTION_KEYS = Object.keys(
  ORGANISATION_SORT_OPTION_FIELD
);

// organisation team filter options
export const ORG_TEAM_FILTER_OPTIONS = {
  ROLE: "role",
};

/** Project sort options */
export const PROJECT_SORT_OPTION_FIELD = {
  SORT_OPTION_MOSTRECENT: "modified_date",
  SORT_OPTION_NAME: "name",
  SORT_OPTION_BRAND: "brand",
};

export const PROJECTS_FILTER_OPTIONS = {
  STATUS: "status",
};

// default sort options, e.g. descending or ascending
// descending is true, ascending is false
export const PROJECT_DESC_SORT_OPTIONS = {
  [Object.keys(PROJECT_SORT_OPTION_FIELD)[0]]: true, // most recent
  [Object.keys(PROJECT_SORT_OPTION_FIELD)[1]]: false, // name
  [Object.keys(PROJECT_SORT_OPTION_FIELD)[2]]: false, // brand
};

export const PROJECT_SORT_OPTION_KEYS = Object.keys(PROJECT_SORT_OPTION_FIELD);

export const ADMIN_PROJECTS_FILTER_OPTIONS = {
  BELONGING_TO_ORG: "belongingToOrg",
  STATUS: "status",
};

export const BELONGING_TO_KEYS = {
  ALL: "all",
  IN_ORGANISATION: "in-organisation",
  NOT_IN_ORGANISATION: "not-in-organisation",
};

export const PROJECT_BELONGING_TO_OPTIONS = [
  {
    title: ProjectStrings.PROJECTS_FILTER_ALL_ORGANISATION,
    value: BELONGING_TO_KEYS.ALL,
  },
  {
    title: ProjectStrings.PROJECTS_FILTER_IN_ORGANISATION,
    value: BELONGING_TO_KEYS.IN_ORGANISATION,
  },
  {
    title: ProjectStrings.PROJECTS_FILTER_NOT_IN_ORGANISATION,
    value: BELONGING_TO_KEYS.NOT_IN_ORGANISATION,
  },
];

export const PROJECT_STATUS_OPTIONS = [
  {
    title: DefaultStrings.FILTER_ALL,
    value: DefaultStrings.FILTER_ALL,
  },
  {
    title: PROJECT_STATUS.ACTIVE,
    value: PROJECT_STATUS.ACTIVE,
  },
  {
    title: PROJECT_STATUS.INACTIVE,
    value: PROJECT_STATUS.INACTIVE,
  },
  {
    title: PROJECT_STATUS.DEVELOPMENT,
    value: PROJECT_STATUS.DEVELOPMENT,
  },
];

/** Team sort options */
export const TEAM_SORT_OPTION_FIELD = {
  SORT_OPTION_MOSTRECENT: "updatedAt",
  SORT_OPTION_NAME: "name",
  SORT_OPTION_ROLE: "permissionsKey",
};

// default sort options, e.g. descending or ascending
// descending is true, ascending is false
export const TEAM_DESC_SORT_OPTIONS = {
  [Object.keys(TEAM_SORT_OPTION_FIELD)[0]]: true, // most recent
  [Object.keys(TEAM_SORT_OPTION_FIELD)[1]]: false, // name
  [Object.keys(TEAM_SORT_OPTION_FIELD)[2]]: false, // role
};

export const TEAM_SORT_OPTION_KEYS = Object.keys(TEAM_SORT_OPTION_FIELD);

export const GUEST_SORT_OPTION_KEYS = Object.keys(TEAM_SORT_OPTION_FIELD).slice(
  0,
  2
);

// project team filter options
export const PROJECT_TEAM_FILTER_OPTIONS = {
  ROLE: "role",
};

/** Devices sort options */
export const DEVICE_SORT_OPTION_FIELD = {
  SORT_OPTION_MOSTRECENT: "updatedAt",
  SORT_OPTION_IMEI: "deviceId",
  SORT_OPTION_STOREID: "storeId",
  SORT_OPTION_ONLINE: "updatedAt",
  SORT_OPTION_COMPLIANCE: "state",
};

// default sort options, e.g. descending or ascending
// descending is true, ascending is false
export const DEVICE_DESC_SORT_OPTIONS = {
  [Object.keys(DEVICE_SORT_OPTION_FIELD)[0]]: true, // most recent
  [Object.keys(DEVICE_SORT_OPTION_FIELD)[1]]: false, // imei
  [Object.keys(DEVICE_SORT_OPTION_FIELD)[2]]: false, // storeId
  [Object.keys(DEVICE_SORT_OPTION_FIELD)[3]]: true, // online
  [Object.keys(DEVICE_SORT_OPTION_FIELD)[4]]: true, // compliance
};

// device sort options
export const DEVICE_SORT_OPTION_KEYS = Object.keys(DEVICE_SORT_OPTION_FIELD);

// device filter options
export const DEVICE_FILTER_OPTIONS = {
  COUNTRY: "country",
  RETAILER: "retailerName",
  TAGS: "tags",
  DEVICES: "devices",
};

// device status options
export const DEVICE_STATUS_KEYS = {
  ONLINE: "online",
  OFFLINE: "offline",
  COMPLIANT: "compliant",
  NON_COMPLIANT: "noncompliant",
};

// device status options
export const DEVICES_STATUS_OPTIONS = [
  {
    title: DefaultStrings.FILTER_ALL,
    value: DefaultStrings.FILTER_ALL,
    disabled: false,
  },
  {
    title: DeviceStrings.FILTER_DEVICE_STATUS_ONLINE,
    value: DEVICE_STATUS_KEYS.ONLINE,
    disabled: false,
  },
  {
    title: DeviceStrings.FILTER_DEVICE_STATUS_OFFLINE,
    value: DEVICE_STATUS_KEYS.OFFLINE,
    disabled: false,
  },
  {
    title: DeviceStrings.FILTER_DEVICE_STATUS_COMPLIANT,
    value: DEVICE_STATUS_KEYS.COMPLIANT,
    disabled: false,
  },
  {
    title: DeviceStrings.FILTER_DEVICE_STATUS_NON_COMPLIANT,
    value: DEVICE_STATUS_KEYS.NON_COMPLIANT,
    disabled: false,
  },
];

export const navigationBarHeight = "80px";
export const navigationBarWidth = "80px";
