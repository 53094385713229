import { API_PATHS, postPrivateRequest } from "./ApiService";

export const checkDomainApi = (domain) => {
  const path = API_PATHS.ORGANISATION_CHECK_DOMAIN;
  console.debug("checkDomain", path);
  return postPrivateRequest({ path, data: { domain } });
};

export const checkUserEmailApi = ({ email, organisationId }) => {
  const path = API_PATHS.ORGANISATION_CHECK_USER_EMAIL;
  const data = { email, organisationId };
  console.debug("checkUserEmailApi", path, data);
  return postPrivateRequest({ path, data });
};

export const precreateApi = (connectedDomains) => {
  const path = API_PATHS.ORGANISATION_PRECREATE;
  console.debug("precreateOrganisation", path);
  return postPrivateRequest({ path, data: { connectedDomains } });
};

export const createApi = ({
  organisationId,
  name,
  connectedDomains,
  imageURL,
}) => {
  const path = API_PATHS.ORGANISATION_CREATE;
  const data = { organisationId, name, connectedDomains, imageURL };
  console.debug("createOrganisation", path, data);
  return postPrivateRequest({ path, data });
};

export const updateInfoApi = ({ organisationId, info }) => {
  const path = API_PATHS.ORGANISATION_UPDATE_INFO;
  const data = {
    organisationId,
    ...info,
  };

  console.debug("updateOrganisationInfo", path, data);
  return postPrivateRequest({ path, data });
};

export const deleteInfoApi = (organisationId) => {
  const path = API_PATHS.ORGANISATION_DELETE_INFO;
  const data = {
    organisationId,
  };

  console.debug("deleteOrganisationInfo", path, data);
  return postPrivateRequest({ path, data });
};

export const removeUserApi = ({ organisationId, userId }) => {
  const path = API_PATHS.ORGANISATION_USER_REMOVE;
  const data = {
    organisationId,
    targetUserId: userId,
  };
  console.debug("removeUser", path, data);
  return postPrivateRequest({ path, data });
};

export const updateUserRoleApi = ({ organisationId, userId, role }) => {
  const path = API_PATHS.ORGANISATION_USER_UPDATE_ROLE;
  const data = {
    organisationId,
    targetUserId: userId,
    role,
  };
  console.debug("updateUserRole", path, data);
  return postPrivateRequest({ path, data });
};

export const statsOrganisationApi = async (organisationId) => {
  const path = API_PATHS.ORGANISATION_STATS;
  const data = {
    organisationId,
  };
  console.debug("statsOrganisation", path, data);
  return await postPrivateRequest({ path, data });
};

export const acceptOrganisationInviteApi = async ({
  organisationId,
  userId,
  code,
}) => {
  const path = API_PATHS.ORGANISATION_INVITE_ACCEPT;

  const data = { organisationId, userId, code };
  console.debug("acceptOrganisationInviteApi", path, data);
  return await postPrivateRequest({ path, data });
};

export const declineOrganisationInviteApi = async ({
  organisationId,
  userId,
  code,
}) => {
  const path = API_PATHS.ORGANISATION_INVITE_DECLINE;

  const data = { organisationId, userId, code };
  console.debug("declineOrganisationInviteApi", path, data);
  return await postPrivateRequest({ path, data });
};

// organisations for SuperAdmin
export const getAllOrganisationsApi = ({
  limit,
  orderBy,
  orderDesc,
  searchValue,
  lastKey,
}) => {
  const path = API_PATHS.ORGANISATIONS_LIST;
  const data = {
    limit,
    orderBy,
    orderDesc,
    searchValue,
    lastKey,
  };
  console.debug("getAllOrganisationsApi", path, data);
  return postPrivateRequest({ path, data });
};
// organisations for user
export const getMyOrganisationsApi = ({
  limit,
  orderBy,
  orderDesc,
  searchValue,
  lastKey,
}) => {
  const path = API_PATHS.ORGANISATIONS_MY_LIST;
  const data = {
    limit,
    orderBy,
    orderDesc,
    searchValue,
    lastKey,
  };
  console.debug("getMyOrganisationsApi", path, data);
  return postPrivateRequest({ path, data });
};

export const getOrganisationUsersApi = ({
  organisationId,
  limit,
  orderBy,
  orderDesc,
  searchValue,
  lastKey,
  userRole,
  userRoles,
}) => {
  const path = API_PATHS.ORGANISATION_USERS_LIST;

  const data = {
    organisationId,
    limit,
    orderBy,
    orderDesc,
    searchValue,
    lastKey,
    userRole,
    userRoles,
  };

  console.debug("getOrganisationUsersApi", path, data);
  return postPrivateRequest({ path, data });
};

export const inviteUsersApi = ({ organisationId, invites }) => {
  const path = API_PATHS.ORGANISATION_INVITE_SEND;

  const data = { organisationId, invites };
  console.debug("inviteUsersApi", path, data);
  return postPrivateRequest({ path, data });
};

export const updateUserInviteApi = ({ organisationId, code, role }) => {
  const path = API_PATHS.ORGANISATION_INVITE_UPDATE;

  const data = { organisationId, code, role };
  console.debug("updateUserInviteApi", path, data);
  return postPrivateRequest({ path, data });
};

export const deleteUsersInviteApi = ({ organisationId, code }) => {
  const path = API_PATHS.ORGANISATION_INVITE_DELETE;

  const data = { organisationId, code };
  console.debug("deleteUsersInviteApi", path, data);
  return postPrivateRequest({ path, data });
};

export const getUsersPendingInviteApi = ({
  organisationId,
  searchValue,
  lastKey,
  limit,
  orderBy,
  ordeDesc,
}) => {
  const path = API_PATHS.ORGANISATION_INVITE_PENDING;

  const data = {
    organisationId,
    searchValue,
    lastKey,
    limit,
    orderBy,
    ordeDesc,
  };
  console.debug("getUsersPendingInviteApi", path, data);
  return postPrivateRequest({ path, data });
};

export const leaveApi = async ({ organisationId, userId }) => {
  const path = API_PATHS.ORGANISATION_USER_LEAVE;

  const data = { organisationId, userId };
  console.debug("leaveApi", path);
  return await postPrivateRequest({ path, data });
};

export const getOrganisationPreviewApi = ({ inviteCode, organisationId }) => {
  const path = API_PATHS.ORGANISATION_PREVIEW;

  const data = { inviteCode, organisationId };
  console.debug("getOrganisationPreviewApi", path, data);
  return postPrivateRequest({ path, data });
};

export const refreshOrganisationDevicesOnlineStates = (
  organisationId,
  lastDays,
  forceRefresh
) => {
  const path = API_PATHS.ORGANISATION_REFRESH_DEVICES_ONLINE_STATES;
  const req = {
    organisationId,
    lastDays,
    forceRefresh,
  };

  console.debug("refreshOrganisationDevicesOnlineStates", path, req);
  return postPrivateRequest({ path, data: req });
};
