import { areDictsEqual } from "utils/generalUtils";
import { DefaultStrings } from "strings";

export const isDeviceListEqual = (p1, p2) =>
  p1.projectId === p2.projectId &&
  p1.filter === p2.filter &&
  p1.live === p2.live &&
  p1.sortOption === p2.sortOption &&
  p1.sortDesc === p2.sortDesc &&
  p1.multiple === p2.multiple &&
  areDictsEqual(p1.permissions, p2.permissions);

export const isEqual = (p1, p2) =>
  isDeviceListEqual(p1, p2) &&
  p1.default?.deviceId === p2.default?.deviceId &&
  p1.default?.tab === p2.default?.tab &&
  areDictsEqual(p1.permissions, p2.permissions) &&
  p1.multiple === p2.multiple;

/** Build filter items for dropdown list */
export const buildFilterItems = (devices, key) => {
  // if no devices return empty map
  if (!devices) return new Map();

  const items = devices
    .map((d) => d[key]) // get items from device by key
    .filter((item) => item); // remove undefined items if appears

  const itemsMap = new Map();

  const updateMap = (item) => {
    if (itemsMap.has(item)) {
      // Update if key exists
      itemsMap.set(item, {
        title: itemsMap.get(item).title,
        value: itemsMap.get(item).value,
        additionalText: itemsMap.get(item).additionalText + 1,
      });
    } else {
      // Add new key with initial occurance value
      itemsMap.set(item, {
        title: item,
        value: item,
        additionalText: 1,
      });
    }
  };

  // Count items occurrence for devices
  items.forEach((item) => {
    if (!item) return;

    if (Array.isArray(item)) {
      // if item is array of items go through them and add to the map collection
      item.forEach((arrItem) => updateMap(arrItem));
    } else {
      // item as string
      updateMap(item);
    }
  });

  // Sort ascending
  const sortedAsc = new Map([...itemsMap].sort());

  return new Map([
    [
      DefaultStrings.FILTER_ALL,
      { title: DefaultStrings.FILTER_ALL, value: DefaultStrings.FILTER_ALL },
    ],
    ...sortedAsc,
  ]);
};
