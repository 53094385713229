import React, { useMemo, useContext, useEffect } from "react";
import { matchPath, useLocation } from "react-router-dom";
import AddToQueueOutlinedIcon from "@material-ui/icons/AddToQueueOutlined";
import QueuePlayNextOutlinedIcon from "@material-ui/icons/QueuePlayNextOutlined";

import { DefaultStrings, ProjectStrings } from "strings";
import { useProjectsTabs } from "./useProjectsTabs";
import { useCurrentOrganisation } from "services/UserService";
import { PROJECT_DESC_SORT_OPTIONS, PROJECT_SORT_OPTION_KEYS, PROJECT_STATUS_OPTIONS, PROJECTS_FILTER_OPTIONS } from "./config";
import MainContext from "context/MainContext";
import { useUserOrganisationPermissions } from "services/OrganisationService";
import { GLOBAL_ACION_TYPES } from "context/globalActionTypes";
import { ROUTES } from "route";
import { SortIcon, ProjectStatusIcon } from "assets/icons";

export const useProjectsConfig = ({ userId }) => {
  const location = useLocation();
  const {
    sortFieldValue,
    sortDefaultFieldValue,
    sortDescValue,
    filterValue,
    onFilter,
    onSort,
    onSearch,
    onAction,
    initialSort,
    onClearAllFilter,
  } = useContext(MainContext);
  const selectedOrganisationId = useCurrentOrganisation();
  const tabs = useProjectsTabs(selectedOrganisationId);

  const { canWriteProjects } = useUserOrganisationPermissions({
    userId,
    organisationId: selectedOrganisationId,
  });

  const projectSortOptions = useMemo(
    () =>
      [
        {
          title: DefaultStrings.SORT_BY,
          icon: <SortIcon />,
          items: PROJECT_SORT_OPTION_KEYS.map((opt) => ({
            label: ProjectStrings[opt],
            value: opt,
            desc: opt in sortDescValue ? sortDescValue[opt] : PROJECT_DESC_SORT_OPTIONS[opt],
            selected: sortFieldValue ? opt === sortFieldValue : sortFieldValue,
            onClick: () => onSort(opt, PROJECT_DESC_SORT_OPTIONS[opt]),
          })),
          defaultSelected: sortDefaultFieldValue,
        },
      ],
    [onSort, sortDescValue, sortFieldValue, sortDefaultFieldValue]
  );

  // projects selected filter value
  const selectedProjectStatusValue = filterValue[PROJECTS_FILTER_OPTIONS.STATUS] || DefaultStrings.FILTER_ALL;

  const onSelectProjectFilter = (filterKey, val, defaultValue) => {
    onFilter(filterKey, val === filterValue[filterKey] ? defaultValue : val);
  };

  // projects status filter
  const onSelectProjectStatus = (val) => onSelectProjectFilter(PROJECTS_FILTER_OPTIONS.STATUS, val, DefaultStrings.FILTER_ALL);

  // projects filters
  const projectsFilterOptions = [
    {
      title: ProjectStrings.PROJECTS_FILTER_PROJECT_STATUS,
      icon: <ProjectStatusIcon />,
      items: PROJECT_STATUS_OPTIONS,
      selected: selectedProjectStatusValue,
      defaultSelected: DefaultStrings.FILTER_ALL,
      defaultExpanded: true,
      onClick: onSelectProjectStatus,
    },
  ];

  // current tab index
  const currentTabIndex = useMemo(
    () => {
      const index = tabs.findIndex((tab) =>
        matchPath(location.pathname, { path: tab.path, exact: true })
      );
      return index === -1 ? 0 : index;
    },
    [location.pathname, tabs]
  );

  useEffect(() => {
    if (matchPath(location.pathname, { path: ROUTES.PROJECTS })) {
      const key = PROJECT_SORT_OPTION_KEYS[0];
      initialSort(key, PROJECT_DESC_SORT_OPTIONS[key]);
      onClearAllFilter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, currentTabIndex]);

  const defaultConfig = {
    appBar: {
      title: DefaultStrings.TITLE,
      sort: projectSortOptions,
      filter: projectsFilterOptions,
      tabDefault: tabs[currentTabIndex]?.key,
      onSearch: (value) =>
        value && value.length > 0 ? onSearch(value) : onSearch(null),
      tabs,
    },
    actionButton: {
      actions: [
        {
          icon: <AddToQueueOutlinedIcon />,
          name: ProjectStrings.ADD_PROJECT_DIALOG_CREATE,
          onClick: () => onAction(GLOBAL_ACION_TYPES.PROJECT_CREATE, true),
          disabled: selectedOrganisationId && !canWriteProjects,
        },
        {
          icon: <QueuePlayNextOutlinedIcon />,
          name: ProjectStrings.ADD_PROJECT_DIALOG_JOIN,
          onClick: () => onAction(GLOBAL_ACION_TYPES.PROJECT_JOIN, true),
        },
      ],
    },
  };

  return [
    {
      // default route
      // shared projects, my projects, all projects
      path: tabs[currentTabIndex]?.path,
      ...defaultConfig
    },
    {
      // join project
      path: ROUTES.PROJECT_JOIN,
      ...defaultConfig
    },
    {
      // new project
      path: ROUTES.PROJECT_NEW,
      ...defaultConfig
    },
  ];
};
