import React from "react";
import Box from "@material-ui/core/Box";

import { useStyles } from "./style";

export const SelectItem = ({ title, icon, showBottomBorder }) => {
  const classes = useStyles();

  return <>
    <Box sx={{ display: "flex" }}>
      {title}
      {icon && <div className={classes.icon}>{icon}</div>}
    </Box>
    {showBottomBorder && (
      <div className={classes.bottomBorder}></div>
    )}
  </>;
};
