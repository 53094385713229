import HomeStrings from "./home";
import NavigationStrings from "./navigation";
import ProjectStrings from "./project";
import ProfileStrings from "./profile";
import DeviceStrings from "./device";
import DeployStrings from "./deploy";
import ErrorStrings from "./error";
import NewsStrings from "./news";
import ChatStrings from "./chat";
import OrganisationStrings from "./organisation";
import TeamCodeStrings from "./teamCode";
import {
  STRINGS as EventStrings,
  SELF_STRINGS as EventSelfStrings,
  TARGET_YOU as EventYouString,
} from "./events";

const STRING = {
  APP_NAME: "MyPlayer",

  BUTTON_ACTIONS: "Actions",
  BUTTON_BACK: "Back",
  BUTTON_CANCEL: "Cancel",
  BUTTON_VIEW: "View",
  BUTTON_CONFIRM: "Confirm",
  BUTTON_OVERFLOW: "Overflow Actions",
  BUTTON_SEARCH: "Search",
  BUTTON_SORT: "Sort",
  BUTTON_FILTER: "Filter",
  BUTTON_IMAGE: "Image Upload",
  BUTTON_OK: "OK",
  BUTTON_CLOSE: "Close",
  BUTTON_JOIN: "Join",
  BUTTON_REMOVE: "Remove",
  BUTTON_DELETE: "Delete",
  BUTTON_REJECT: "Reject",
  BUTTON_APPROVE: "Approve",
  BUTTON_SEND: "Send",
  BUTTON_CLEAR: "Clear",
  BUTTON_CLEAR_ALL: "Clear All",

  CARD_SHOW_MORE: "+ more",
  CARD_NOTES_TITLE: "Note",
  CARD_NOTES_INPUT: "Add text...",

  COPY_CLIPBOARD: "Copied to clipboard",

  DOWNLOAD: "Download",

  ERROR_MSG_WRONG_PIN: "Incorrect pin, please try again",
  ERROR_MSG: "An error has occurred!",
  ERROR_NO_ACCESS: "No access to this content",

  EVENT_NEW_DEVICES: "New devices",
  FETCHING_DATA: "...",
  LOADING: "Loading...",

  PERMISSONS_NO_PERMISSION: "(No permission to access this data)",
  PHOTOS: "Photos",

  NOTE_BOLD: "Bold",
  NOTE_UNDERLINE: "Underline",
  NOTE_ITALIC: "Italic",
  NOTE_LINK: "Insert Link",

  SEARCH: "Search",
  TITLE: "MyProjects",

  TODAY: "Today",

  UNDER_CONSTRUCTION: "This section is under construction.\nCheck back soon.",
  LOCKED: "Locked",
  FILTER_ALL: "All",
  SORT_BY: "Sort by",
};

export {
  STRING as DefaultStrings,
  HomeStrings,
  NavigationStrings,
  ProjectStrings,
  DeviceStrings,
  ProfileStrings,
  DeployStrings,
  ErrorStrings,
  EventStrings,
  EventSelfStrings,
  EventYouString,
  NewsStrings,
  ChatStrings,
  OrganisationStrings,
  TeamCodeStrings,
};
