import React, { useState, useContext, useEffect, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import Grid from "@material-ui/core/Grid";

import ProjectDeviceStatCard from "ui/cards/deviceStatCard/ProjectDeviceStatCard";
import ProjectSmartTagsStatCard from "ui/cards/deviceStatCard/ProjectSmartTagsStatCard";
import HardwareCard from "ui/cards/HardwareCard";
import PinCard from "ui/cards/PinCard";
import StatusCard from "ui/cards/StatusCard";
import ProjectCard from "ui/cards/projectCard/ProjectCard";
import ProjectDialog from "ui/dialogs/ProjectDialog";
import ProjectNoteCard from "ui/cards/ProjectNoteCard";
import { OrganisationsSelectDialog } from "ui/dialogs/organisationsSelectDialog";

import { leaveProject as leaveProjectApi } from "services/ApiService";
import {
  deleteProject as deleteProjectApi,
  useUserProjectPermissions,
  useProjectInfo,
} from "services/ProjectService";
import { useMobileLayout, useConfirm } from "hooks/uiHooks";
import { JoinDialog, ProjectTransferDialog } from "ui/dialogs";
import {
  useTotalPhysicalDevicesCount,
  useTotalSmartTagsCount,
} from "services/DeviceService";
import { useBreakpoints } from "services/UiService";
import { DefaultStrings, ProjectStrings } from "strings";
import { getProjectsRoute } from "route";
import { withInProjectCheck } from "hocs";
import MainContext from "context/MainContext";
import { GLOBAL_ACION_TYPES } from "context/globalActionTypes";

const useStyles = makeStyles((theme) => ({
  base: ({ mobile }) => ({
    display: "flex",
    flexWrap: mobile ? "wrap" : "nowrap",
    padding: theme.spacing(mobile ? 2 : 3),
  }),
  groupV: {
    flexGrow: 2,
    display: "flex",
    flexDirection: "column",
  },
  groupH: ({ mobile }) => ({
    flexGrow: 1,
    display: "flex",
    flexWrap: mobile ? "wrap" : "nowrap",
  }),
  infoContainer: {
    minHeight: 520,
    width: "100%",
    flexGrow: 1,
  },
  container: ({ mobile }) => ({
    minHeight: mobile ? 200 : "auto",
    height: "100%",
    minWidth: 180,
  }),
}));

// Trello ticket on permissions: https://trello.com/c/Tfm5iRaP/103-add-permission-control-for-project-overview-page
const ProjectOverviewPage = ({ userId }) => {
  const params = useParams();
  const confirm = useConfirm();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const mobile = useMobileLayout();
  const { isTablet } = useBreakpoints();
  const classes = useStyles({ mobile, isTablet });
  const projectId = params.projectId;
  const info = useProjectInfo({ projectId, userId });
  const { actionType, onAction } = useContext(MainContext);

  const [projectIdToJoin, setProjectIdToJoin] = useState(null);
  const [showTransferDialog, setShowTransferDialog] = useState(false);
  const [showProjectDialog, setShowProjectDialog] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState(null);

  // permissions control
  const { canWriteProject, canReadDevices, canWriteDevices } =
    useUserProjectPermissions({ userId, projectId });
  const deviceCount = useTotalPhysicalDevicesCount(canReadDevices && projectId);
  const smartTagsCount = useTotalSmartTagsCount(canReadDevices && projectId);
  const hasDevice = deviceCount > 0;
  const hasSmartTags = smartTagsCount > 0;
  const [projectLoading, setProjectLoading] = useState(false);

  const configDialog = {
    onClose: () => {
      setShowProjectDialog(false);
    },
    onConfirm: () => {
      setShowProjectDialog(false);
      setProjectLoading(true);
    },
    onComplete: () => {
      setProjectLoading(false);
    },
  };

  const configCard = {
    showBrand: true,
    showDescription: true,
    showPin: false,
    loading: projectLoading,
  };

  const configJoinDialog = {
    projectId: projectIdToJoin,
    userId,
    hideViewBtn: true,
    onClose: () => setProjectIdToJoin(null),
  };

  if (canWriteProject) {
    configCard.onClick = () => {
      setShowProjectDialog(true);
    };
  }

  const onOrgSelect = (organisation) => {
    setShowTransferDialog(true);
    setSelectedOrg(organisation);
  };

  const onOrgSelectDialogClose = () => {
    onAction(GLOBAL_ACION_TYPES.PROJECT_TRANSFER, false);
  };

  const onTransferDialogClose = () => {
    setShowTransferDialog(false);
    setSelectedOrg(null);
  };

  const onTransferDialogComplete = () => {
    onAction(GLOBAL_ACION_TYPES.PROJECT_TRANSFER, false);
    setShowTransferDialog(false);
    setSelectedOrg(null);
  };

  const deleteProject = useCallback(() => {
    confirm({ message: ProjectStrings.OVERVIEW_PROJECT_DELETE_CONFIRM })
      .then(() => {
        // delete project and show toast
        deleteProjectApi(projectId)
          .then(() => {
            onAction(GLOBAL_ACION_TYPES.PROJECT_DELETE, false);
            // redirect to projects page
            history.push(getProjectsRoute());

            enqueueSnackbar(ProjectStrings.OVERVIEW_PROJECT_DELETED, {
              variant: "success",
            });
          })
          .catch((error) => {
            onAction(GLOBAL_ACION_TYPES.PROJECT_DELETE, false);
            enqueueSnackbar(DefaultStrings.ERROR_MSG, {
              variant: "error",
            });
            console.warn(error);
          });
      })
      .catch(() => {
        onAction(GLOBAL_ACION_TYPES.PROJECT_DELETE, false);
      });
  }, [confirm, enqueueSnackbar, history, onAction, projectId]);

  const leaveProject = useCallback(() => {
    confirm({ message: ProjectStrings.OVERVIEW_PROJECT_LEAVE_CONFIRM })
      .then(() => {
        leaveProjectApi(projectId)
          .then(() => {
            onAction(GLOBAL_ACION_TYPES.PROJECT_LEAVE, false);
            // redirect to projects page
            history.push(getProjectsRoute());
            enqueueSnackbar(ProjectStrings.OVERVIEW_PROJECT_LEFT, {
              variant: "success",
            });
          })
          .catch((error) => {
            onAction(GLOBAL_ACION_TYPES.PROJECT_LEAVE, false);
            enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
            console.warn(error);
          });
      })
      .catch(() => {
        onAction(GLOBAL_ACION_TYPES.PROJECT_LEAVE, false);
      });
  }, [confirm, enqueueSnackbar, history, onAction, projectId]);

  useEffect(() => {
    if (actionType[GLOBAL_ACION_TYPES.PROJECT_DELETE]) {
      deleteProject();
    }
    if (actionType[GLOBAL_ACION_TYPES.PROJECT_LEAVE]) {
      leaveProject();
    }
  }, [actionType, deleteProject, leaveProject]);

  return (
    <>
      <div className={classes.base}>
        <Grid spacing={2} container>
          <Grid item xs={12} sm={12} md={4} lg={3} style={{ display: "flex" }}>
            <div className={classes.infoContainer}>
              <ProjectCard
                userId={userId}
                projectId={projectId}
                config={configCard}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={9}>
            <Grid
              container
              spacing={2}
              style={{ height: "100%", minHeight: 540 }}
            >
              {hasDevice && (
                <Grid
                  item
                  xs={12}
                  md={hasSmartTags ? 6 : 8}
                  lg={hasSmartTags ? 5 : 6}
                  xl={hasSmartTags ? 5 : 6}
                >
                  <div className={classes.container}>
                    <ProjectDeviceStatCard
                      projectId={projectId}
                      canRead={canReadDevices}
                      canEdit={canWriteDevices}
                    />
                  </div>
                </Grid>
              )}
              {hasSmartTags && (
                <Grid
                  item
                  xs={12}
                  md={hasDevice ? 6 : 8}
                  lg={hasDevice ? 4 : 8}
                  xl={hasDevice ? 3 : 8}
                >
                  <div className={classes.container}>
                    <ProjectSmartTagsStatCard
                      projectId={projectId}
                      canRead={canReadDevices}
                      canEdit={canWriteDevices}
                    />
                  </div>
                </Grid>
              )}
              {hasDevice && (
                <Grid
                  item
                  xs={12}
                  sm={hasSmartTags ? 6 : 6}
                  md={hasSmartTags ? 6 : 4}
                  lg={hasSmartTags ? 3 : 3}
                  xl={hasSmartTags ? 2 : 3}
                >
                  <div className={classes.container}>
                    <PinCard projectId={projectId} userId={userId} />
                  </div>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                sm={6}
                md={
                  !hasDevice && !hasSmartTags
                    ? 12
                    : (hasSmartTags && !hasDevice) ||
                      (!hasSmartTags && hasDevice)
                    ? 4
                    : 6
                }
                lg={!hasDevice && !hasSmartTags ? 12 : hasSmartTags ? 4 : 3}
                xl={
                  !hasDevice && !hasSmartTags
                    ? 12
                    : hasSmartTags && !hasDevice
                    ? 4
                    : !hasSmartTags && hasDevice
                    ? 3
                    : 2
                }
              >
                <div className={classes.container}>
                  <StatusCard userId={userId} projectId={projectId} />
                </div>
              </Grid>
              {hasDevice && (
                <Grid
                  item
                  xs={12}
                  md={hasSmartTags ? 6 : 8}
                  lg={hasSmartTags ? 8 : 6}
                  xl={hasSmartTags ? 6 : 6}
                >
                  <div className={classes.container}>
                    <HardwareCard userId={userId} projectId={projectId} />
                  </div>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                sm={
                  (!hasDevice && !hasSmartTags) || (hasSmartTags && !hasDevice)
                    ? 6
                    : 12
                }
                md={
                  (!hasDevice && !hasSmartTags) ||
                  (hasSmartTags && !hasDevice) ||
                  (!hasSmartTags && hasDevice)
                    ? 12
                    : 6
                }
                lg={(!hasDevice && !hasSmartTags) || hasSmartTags ? 12 : 6}
                xl={
                  (!hasDevice && !hasSmartTags) || (hasSmartTags && !hasDevice)
                    ? 12
                    : 6
                }
              >
                <div className={classes.container}>
                  <ProjectNoteCard userId={userId} projectId={projectId} />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <ProjectDialog
        projectId={projectId}
        open={showProjectDialog}
        config={configDialog}
      />
      <OrganisationsSelectDialog
        title={ProjectStrings.OVERVIEW_PROJECT_TRANSFER_TO_ORGANISATION_TITLE}
        open={actionType[GLOBAL_ACION_TYPES.PROJECT_TRANSFER]}
        currentOrganisationId={info?.organisationId}
        onSelect={onOrgSelect}
        onClose={onOrgSelectDialogClose}
        showAllOrganisations
      />
      <ProjectTransferDialog
        open={showTransferDialog}
        projectId={projectId}
        organisation={selectedOrg}
        onComplete={onTransferDialogComplete}
        onClose={onTransferDialogClose}
      />
      <JoinDialog open={false} config={configJoinDialog} />
    </>
  );
};

export default withInProjectCheck(ProjectOverviewPage);
