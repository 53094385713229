import React, { useState } from "react";

import {
  useLiveSmartTagsCount,
  useOnlineSmartTagsCount,
  useDecommissionedSmartTagsCount,
  DEVICE_PLATFORMS,
} from "services/DeviceService";
import { useProjectDevicesOnlineState } from "services/ProjectService";
import StatCard from "./statCard/StatCard";
import { ProjectStrings } from "strings";
import { SmartTagIcon } from "assets/icons";

const ProjectSmartTagsStatCard = ({ projectId, canRead, canEdit }) => {
  const [isDoAction, setIsDoAction] = useState(false);

  const live = useLiveSmartTagsCount(canRead && projectId);
  const online = useOnlineSmartTagsCount(canRead && projectId);
  const decommissioned = useDecommissionedSmartTagsCount(canRead && projectId);

  const charts = useProjectDevicesOnlineState(isDoAction && projectId, 90, [
    DEVICE_PLATFORMS.QRID,
  ]);

  const onActionHandle = () => {
    setIsDoAction(!isDoAction);
  };

  return (
    <StatCard
      icon={<SmartTagIcon />}
      title={ProjectStrings.OVERVIEW_SMART_TAGS_STAT_TITLE}
      live={live}
      online={online}
      decommissioned={decommissioned}
      charts={charts}
      canRead={canRead}
      canEdit={canEdit}
      onAction={onActionHandle}
    />
  );
};

export default ProjectSmartTagsStatCard;
