import React from "react";
import Button from "@material-ui/core/Button";

import { DeviceStrings } from "strings";
import { useStyles } from "./styles";

export const SelectContent = ({ selectedCount, onSelectAllToggle, onSelectCancel }) => {
  const classes = useStyles();
  return (
    <div className={classes.multiRow}>
      <Button className={classes.multiButton} onClick={onSelectAllToggle}>
        {selectedCount > 0
          ? DeviceStrings.MULTI_SELECT_NONE
          : DeviceStrings.MULTI_SELECT_ALL}
      </Button>
      <Button className={classes.multiButton} onClick={onSelectCancel}>
        {DeviceStrings.MULTI_SELECT_CANCEL}
      </Button>
    </div>
  );
};
