import React, { useMemo, useState, useCallback, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { useSnackbar } from "notistack";

import { useUsersAwaitingApprovalCount } from "services/ProjectService";
import {
  joinApproveProject,
  joinRejectProject,
  getUsersAwaitingApprovalProject,
} from "services/ApiService";
import { useUserInfo } from "services/UserService";
import { useMobileLayout, useConfirm } from "hooks/uiHooks";
import UserAvatar from "ui/UserAvatar";
import { useStyles } from "./style";
import { TeamCodeStrings, ProjectStrings, DefaultStrings } from "strings";
import { OverflowMenu } from "components";
import { CheckIcon, DeleteIcon } from "assets/icons";

function PendingApprovalsCard({ projectId, canWrite, onOpen }) {
  const { enqueueSnackbar } = useSnackbar();
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);
  const confirm = useConfirm();

  const [awaitingApproval, setAwaitingApproval] = useState();
  const [totalAwaitingApproval, setTotalAwaitingApproval] = useState();

  const usersApprovalsCount = useUsersAwaitingApprovalCount(projectId);
  const userInfo = useUserInfo(
    awaitingApproval && awaitingApproval.length ? awaitingApproval[0] : null
  );

  const avatarTeamSize = mobile ? 7 : 5;

  const description = useMemo(() => {
    if (!awaitingApproval || !awaitingApproval.length) return null;

    if (awaitingApproval.length === 1) {
      return `${userInfo?.name || userInfo?.email} ${
        TeamCodeStrings.TEAM_AWAITING_APPROVAL_IS_AWAITING_APPROVAL
      }`;
    }
    return `${userInfo?.name || userInfo?.email} and ${
      awaitingApproval.length - 1
    } ${TeamCodeStrings.TEAM_AWAITING_APPROVAL_ARE_AWAITING_APPROVAL}`;
  }, [awaitingApproval, userInfo]);

  const menuAcceptAll = {
    label: TeamCodeStrings.TEAM_AWAITING_APPROVAL_APPROVE_ALL,
    onClick: () => {
      confirm({
        title: ProjectStrings.ADD_PROJECT_JOIN_APPROVE_ALL_CONFIRM_TITLE,
        message: ProjectStrings.ADD_PROJECT_JOIN_APPROVE_ALL_CONFIRM_DESC,
        config: {
          icon: <CheckIcon />,
          btOkColor: "primary",
          btOkTitle: DefaultStrings.BUTTON_APPROVE,
          cbAction: true,
        },
      })
        .then(async ({ onProgress, onClose }) => {
          onProgress(true);
          try {
            await joinApproveProject({ projectId });
            enqueueSnackbar(ProjectStrings.ADD_PROJECT_JOIN_APPROVED, {
              variant: "success",
            });
          } catch (err) {
            console.warn(err);
            enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
          } finally {
            onClose();
            onProgress(false);
          }
        })
        .catch(() => {})
        .finally(() => {});
    },
    disabled: !canWrite,
  };

  const menuRejectAll = {
    label: TeamCodeStrings.TEAM_AWAITING_APPROVAL_REJECT_ALL,
    onClick: () => {
      confirm({
        title: ProjectStrings.ADD_PROJECT_JOIN_REJECT_ALL_CONFIRM_TITLE,
        message: ProjectStrings.ADD_PROJECT_JOIN_REJECT_ALL_CONFIRM_DESC,
        config: {
          icon: <DeleteIcon />,
          btOkColor: "secondary",
          btOkTitle: DefaultStrings.BUTTON_REJECT,
          cbAction: true,
        },
      })
        .then(async ({ onProgress, onClose }) => {
          onProgress(true);
          try {
            await joinRejectProject({ projectId });
            enqueueSnackbar(ProjectStrings.ADD_PROJECT_JOIN_REJECT, {
              variant: "success",
            });
          } catch (err) {
            console.warn(err);
            enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
          } finally {
            onClose();
            onProgress(false);
          }
        })
        .catch(() => {})
        .finally(() => {});
    },
    disabled: !canWrite,
    color: "error",
  };

  const fetchData = useCallback(async () => {
    const requestParams = {
      projectId,
    };

    try {
      const res = await getUsersAwaitingApprovalProject(requestParams);
      const responseData = res.result;
      const { usersIds, total } = responseData;

      setTotalAwaitingApproval(total);
      setAwaitingApproval(usersIds);
    } catch (err) {
      console.log(err);
    }
  }, [projectId]);

  useEffect(() => {
    if (projectId) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    if (
      projectId &&
      typeof totalAwaitingApproval !== "undefined" &&
      usersApprovalsCount !== totalAwaitingApproval
    ) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, totalAwaitingApproval, usersApprovalsCount]);

  if (!usersApprovalsCount || !awaitingApproval?.length) return null;

  return (
    <>
      <Card className={classes.root}>
        <div className={classes.contentWrapper}>
          {!mobile && (
            <div className={classes.avatarsWrapper}>
              {awaitingApproval.map((m, idx) => (
                <Box
                  style={{
                    marginLeft: idx === 0 ? "unset" : "-6px",
                  }}
                  key={m}
                >
                  <UserAvatar userId={m} size={avatarTeamSize} />
                </Box>
              ))}
            </div>
          )}
          {description && (
            <Typography variant="body2">{description}</Typography>
          )}
        </div>
        <div>
          <Button
            className={classes.button}
            onClick={onOpen}
            disabled={!canWrite}
          >
            {TeamCodeStrings.TEAM_AWAITING_APPROVAL_MANAGE_BUTTON}
          </Button>
          <OverflowMenu name="pending-approvals-card" options={[menuAcceptAll, menuRejectAll]} />
        </div>
      </Card>
    </>
  );
}

export default PendingApprovalsCard;
