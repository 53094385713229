  import React, { useContext, useEffect, useMemo } from "react";
  import { matchPath, useLocation } from "react-router-dom";
  import EditIcon from "@material-ui/icons/Edit";
  import ThumbUpAltOutlinedIcon from "@material-ui/icons/ThumbUpAltOutlined";
  import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
  import TabletIcon from "@material-ui/icons/Tablet";
  import { useRouteMatch } from "react-router-dom";

  import {
    useUserProjectPermissions,
    useProjectInfo,
    useUserInProject,
    useProjectDraftExist,
    useProjectDraftApprovals,
  } from "services/ProjectService";
  import { useProjectBasicPermissionsOptions } from "services/PermissionService";
  import {
    useTotalDevicesCount,
    useLiveAlldevicesCount,
  } from "services/DeviceService";
  import { useMyState } from "services/StateService";
  import { getProjectCreatorId } from "utils/projectUtils";
  import { ProjectStrings, DeviceStrings, DefaultStrings } from "strings";
  import MainContext from "context/MainContext";
  import { TEAM_SORT_OPTION_KEYS, TEAM_DESC_SORT_OPTIONS, DEVICE_FILTER_OPTIONS } from "ui/pageLayout/config";
  import { GLOBAL_ACION_TYPES } from "context/globalActionTypes";
  import { QrCodeIcon, DeviceTagIcon, StoreIcon, FlagIcon, DevicesIcon, SortIcon, UserRoleIcon } from "assets/icons";
  import {
    DEVICE_SORT_OPTION_KEYS,
    DEVICE_DESC_SORT_OPTIONS,
    DEVICES_STATUS_OPTIONS,
    DEVICE_STATUS_KEYS,
    PROJECT_TEAM_FILTER_OPTIONS,
  } from "./config";
  import { ROUTES } from "route";
  import { buildFilterItems } from "ui/deviceList/utils";

  /**
   * useProjectActions hook provides project actions based on user and project IDs.
   * 
   * @param {Object} props - Object containing userId and projectId.
   * @param {string} props.userId - The ID of the user.
   * @param {string} props.projectId - The ID of the project.
   * @returns {Object} - An object containing project actions.
   */
  export const useProjectActions = ({ userId, projectId }) => {
    const location = useLocation();
    const {
      sortDescValue,
      sortFieldValue,
      sortDefaultFieldValue,
      actionType,
      onSort,
      onAction,
      initialSort,
      filterValue,
      filterItems,
      onFilter,
      onClearAllFilter,
    } = useContext(MainContext);

    const {
      isSuperAdmin,
      canDeleteProject,
      canReadShowroom,
      canReadDeviceExtra,
      canReadDevices,
      canWriteProjectApprovals,
    } = useUserProjectPermissions({
      userId,
      projectId,
    });
    const teamPermissionsOptions = useProjectBasicPermissionsOptions();
    const isJoinedProj = useUserInProject({ projectId, userId });
    const info = useProjectInfo({ projectId, userId });
    const canLeave = info && getProjectCreatorId(info) !== userId; // anyone but the creator
    const allDevices = useTotalDevicesCount(canReadDevices && projectId);
    const allLiveDevices = useLiveAlldevicesCount(canReadDevices && projectId);
    const noDecommissionDevice = allDevices === allLiveDevices;

    const matchPreview = useRouteMatch(ROUTES.PROJECT_MEDIA_PREVIEW);
    const version = useMyState({ path: `${projectId}/media/version` });
    // current state override path param
    const draftId = version?.id ?? matchPreview?.params.draftId;

    const mediaExist = useProjectDraftExist({
      projectId,
      draftName: draftId,
    });
    const approvals = useProjectDraftApprovals({
      projectId,
      draftName: draftId,
    });

    const hasApproved = (userId, approvals) => {
      if (!userId || !approvals) return false;
      return Object.keys(approvals).some((id) => id === userId);
    };

    const userRolesOptions = useMemo(() => {
      const allUsers = {
        title: DefaultStrings.FILTER_ALL,
        value: DefaultStrings.FILTER_ALL,
      };
      if (teamPermissionsOptions) {
        const perms = [...teamPermissionsOptions];
        perms.unshift(allUsers);
        return perms;
      }
      return teamPermissionsOptions;
    }, [teamPermissionsOptions]);

    // can approve if there is media and not already approved and can write to project
    const canApprove =
      mediaExist && !hasApproved(userId, approvals) && canWriteProjectApprovals;

    const deleteProject = {
      label: ProjectStrings.OVERVIEW_MENU_DELETE,
      onClick: () => onAction(GLOBAL_ACION_TYPES.PROJECT_DELETE, true),
      disabled: !canDeleteProject,
      color: "error",
    };

    const joinProject = {
      label: ProjectStrings.OVERVIEW_MENU_JOIN,
      onClick: () => onAction(GLOBAL_ACION_TYPES.PROJECT_JOIN, projectId),
      disabled: isJoinedProj === true,
    };

    const leaveProject = {
      label: ProjectStrings.OVERVIEW_MENU_LEAVE,
      onClick: () => onAction(GLOBAL_ACION_TYPES.PROJECT_LEAVE, true),
      disabled: !canLeave || isJoinedProj === false,
    };

    const transferProject = {
      label: ProjectStrings.OVERVIEW_MENU_TRANSFER,
      onClick: () => onAction(GLOBAL_ACION_TYPES.PROJECT_TRANSFER, true),
    };

    const mediaEdit = {
      icon: <EditIcon />,
      name: ProjectStrings.ACTION_EDIT,
      onClick: () => onAction(GLOBAL_ACION_TYPES.PROJECT_MEDIA_EDIT, true),
    };

    const mediaApprove = {
      icon: <ThumbUpAltOutlinedIcon />,
      name: ProjectStrings.ACTION_APPROVE,
      onClick: () => onAction(GLOBAL_ACION_TYPES.PROJECT_MEDIA_APPROVE, true),
      disabled: !canApprove,
    };
    
    const mediaShare = {
      icon: <ShareOutlinedIcon />,
      name: ProjectStrings.SHOWROOM_TITLE,
      onClick: () => onAction(GLOBAL_ACION_TYPES.PROJECT_MEDIA_SHARE, true),
      disabled: !canReadShowroom,
    };

    const createQrDevice = {
      icon: <QrCodeIcon />,
      name: DeviceStrings.MENU_CREATE_QR_DEVICE,
      onClick: () =>
        onAction(GLOBAL_ACION_TYPES.PROJECT_DEVICES_QR_DEVICE_CREATE, true),
      disabled: !canReadDeviceExtra,
    };

    const createDisplayDevice = {
      icon: <TabletIcon />,
      name: DeviceStrings.MENU_CREATE_DISPLAY_DEVICE,
      onClick: () =>
        onAction(GLOBAL_ACION_TYPES.PROJECT_DEVICES_INSTRACTION, true),
      disabled: !canReadDeviceExtra,
    };

    // team sort options
    const teamSortOptions = [
      {
        title: DefaultStrings.SORT_BY,
        icon: <SortIcon />,
        items: TEAM_SORT_OPTION_KEYS.map((opt) => ({
          label: ProjectStrings[opt],
          desc: opt in sortDescValue ? sortDescValue[opt] : TEAM_DESC_SORT_OPTIONS[opt],
          value: opt,
          selected: sortFieldValue ? opt === sortFieldValue : sortFieldValue,
          onClick: () => onSort(opt, TEAM_DESC_SORT_OPTIONS[opt]),
        })),
        defaultSelected: sortDefaultFieldValue,
      },
    ];

    // devices sort options
    const devicesSortOptions = [
      {
        title: DefaultStrings.SORT_BY,
        icon: <SortIcon />,
        items: DEVICE_SORT_OPTION_KEYS.map((opt) => ({
          label: DeviceStrings[opt],
          desc: opt in sortDescValue ? sortDescValue[opt] : DEVICE_DESC_SORT_OPTIONS[opt],
          value: opt,
          selected: sortFieldValue ? opt === sortFieldValue : sortFieldValue,
          onClick: () => onSort(opt, DEVICE_DESC_SORT_OPTIONS[opt]),
        })),
        defaultSelected: sortDefaultFieldValue,
      },
    ];

    const onSelectDevicesFilter = (key, selected) => (value) => {
      let newSelected = [...selected];
      const filterAllIdx = newSelected.findIndex(s => s === DefaultStrings.FILTER_ALL);
      const selectedIdx = newSelected.findIndex(s => s === value);

      // Handle filter selection
      if (filterAllIdx !== -1) {
        // Remove all
        newSelected.splice(filterAllIdx, 1);
        // Add value
        newSelected.push(value);
      } else if (selectedIdx !== -1) {
        // Remove value
        newSelected.splice(selectedIdx, 1);
        // If no other items are selected, select all
        if (newSelected.length === 0) {
          newSelected.push(DefaultStrings.FILTER_ALL);
        }
      } else if(value === DefaultStrings.FILTER_ALL) {
        // Select only all
        newSelected = [DefaultStrings.FILTER_ALL];
      } else {
        // Add value
        newSelected.push(value);
      }

      onFilter(key, newSelected);
    };

    // tags filter items
    const tagsFilterItems = DEVICE_FILTER_OPTIONS.TAGS in filterItems ?
      filterItems[DEVICE_FILTER_OPTIONS.TAGS] :
      [...buildFilterItems([], DEVICE_FILTER_OPTIONS.TAGS).values()];
    
    // countries filter items
    const countriesFilterItems = DEVICE_FILTER_OPTIONS.COUNTRY in filterItems ?
      filterItems[DEVICE_FILTER_OPTIONS.COUNTRY] :
      [...buildFilterItems([], DEVICE_FILTER_OPTIONS.COUNTRY).values()];
    
    // retailers filter items
    const retailFilterItems = DEVICE_FILTER_OPTIONS.RETAILER in filterItems ?
      filterItems[DEVICE_FILTER_OPTIONS.RETAILER] :
      [...buildFilterItems([], DEVICE_FILTER_OPTIONS.RETAILER).values()];

    // selected filter values
    // if no filter value, then default filter is "All"
    const selectedDevicesFilterValues = filterValue[DEVICE_FILTER_OPTIONS.DEVICES] || [DefaultStrings.FILTER_ALL];
    const selectedTagsFilterValues = filterValue[DEVICE_FILTER_OPTIONS.TAGS] || [DefaultStrings.FILTER_ALL];
    const selectedCountriesFilterValues = filterValue[DEVICE_FILTER_OPTIONS.COUNTRY] || [DefaultStrings.FILTER_ALL];
    const selectedRetailersFilterValues = filterValue[DEVICE_FILTER_OPTIONS.RETAILER] || [DefaultStrings.FILTER_ALL];

    // filter devices status options based on selected values
    // e.g. if selected [DEVICE_STATUS_KEYS.ONLINE], then filter out [DEVICE_STATUS_KEYS.OFFLINE]
    const filterDevicesStatusOptions = (selected) => {
      let options = [...DEVICES_STATUS_OPTIONS];
      const statusesToFilter = [
        [DEVICE_STATUS_KEYS.ONLINE, DEVICE_STATUS_KEYS.OFFLINE],
        [DEVICE_STATUS_KEYS.OFFLINE, DEVICE_STATUS_KEYS.ONLINE],
        [DEVICE_STATUS_KEYS.COMPLIANT, DEVICE_STATUS_KEYS.NON_COMPLIANT],
        [DEVICE_STATUS_KEYS.NON_COMPLIANT, DEVICE_STATUS_KEYS.COMPLIANT],
      ];

      // if only one filter is selected and it is "All", then all options are enabled
      if (selected.length === 1 && selected.includes(DefaultStrings.FILTER_ALL)) {
        options = options.map(option => ({ ...option, disabled: false }));
        return options;
      }

      statusesToFilter.forEach(([status, oppositeStatus]) => {
        if (selected.includes(status)) {
          // if status is selected, then opposite status is disabled
          options = options.map(option => (option.value === oppositeStatus ? { ...option, disabled: true } : option));
        } else if (!selected.includes(status) && !selected.includes(oppositeStatus)) {
          // if status is not selected and opposite status is not selected, then status is enabled
          options = options.map(option => (option.value === status ? { ...option, disabled: false } : option));
        }
      });
      return options;
    };

    // devices filter options
    const devicesFilterOptions = [
      {
        title: DeviceStrings.FILTER_DEVICES,
        icon: <DevicesIcon />,
        items: filterDevicesStatusOptions(selectedDevicesFilterValues),
        selected: selectedDevicesFilterValues,
        defaultSelected: DefaultStrings.FILTER_ALL,
        multiple: true,
        defaultExpanded: true,
        onClick: onSelectDevicesFilter(DEVICE_FILTER_OPTIONS.DEVICES, selectedDevicesFilterValues),
      },
      {
        title: DeviceStrings.FILTER_TAG,
        icon: <DeviceTagIcon />,
        items: tagsFilterItems,
        selected: selectedTagsFilterValues,
        defaultSelected: DefaultStrings.FILTER_ALL,
        multiple: true,
        onClick: onSelectDevicesFilter(DEVICE_FILTER_OPTIONS.TAGS, selectedTagsFilterValues),
      },
      {
        title: DeviceStrings.FILTER_COUNTRY,
        icon: <FlagIcon />, 
        items: countriesFilterItems,
        selected: selectedCountriesFilterValues,
        defaultSelected: DefaultStrings.FILTER_ALL,
        multiple: true,
        onClick: onSelectDevicesFilter(DEVICE_FILTER_OPTIONS.COUNTRY, selectedCountriesFilterValues),
      },
      {
        title: DeviceStrings.FILTER_RETAILER,
        icon: <StoreIcon />,
        items: retailFilterItems,
        selected: selectedRetailersFilterValues,
        defaultSelected: DefaultStrings.FILTER_ALL,
        multiple: true,
        onClick: onSelectDevicesFilter(DEVICE_FILTER_OPTIONS.RETAILER, selectedRetailersFilterValues),
      },
    ];

    // project team selected filter value
    const selectedTeamRoleValue = filterValue[PROJECT_TEAM_FILTER_OPTIONS.ROLE] || DefaultStrings.FILTER_ALL;
    
    // on select team role
    // if selected value is the same as the current selected value, then use default filter value "All"
    const onSelectTeamRole = (val) => {
      if (val === selectedTeamRoleValue) {
        onFilter(PROJECT_TEAM_FILTER_OPTIONS.ROLE, DefaultStrings.FILTER_ALL);
      } else {
        onFilter(PROJECT_TEAM_FILTER_OPTIONS.ROLE, val);
      }
    };

    // project team filter options
    const projectTeamFilterOptions = [
      {
        title: ProjectStrings.SORT_OPTION_ROLE,
        icon: <UserRoleIcon />,
        items: userRolesOptions,
        selected: selectedTeamRoleValue,
        defaultSelected: DefaultStrings.FILTER_ALL,
        defaultExpanded: true,
        onClick: onSelectTeamRole,
      },
    ];

    const devicesOverflow = [
      {
        label: DeviceStrings.MENU_SELECT_MULTI_DEVICES,
        onClick: () =>
          onAction(
            GLOBAL_ACION_TYPES.PROJECT_DEVICES_MULTIPLE,
            !actionType[GLOBAL_ACION_TYPES.PROJECT_DEVICES_MULTIPLE]
          ),
      },
      {
        label: actionType[GLOBAL_ACION_TYPES.PROJECT_DEVICES_LIVE]
          ? DeviceStrings.MENU_DECOMMISSION_DEVICES
          : DeviceStrings.MENU_LIVE_DEVICES,
        onClick: () => {
          onClearAllFilter();
          onAction(
            GLOBAL_ACION_TYPES.PROJECT_DEVICES_LIVE,
            !actionType[GLOBAL_ACION_TYPES.PROJECT_DEVICES_LIVE]
          )},
        disabled: !canReadDevices,
      },
      {
        label: actionType[GLOBAL_ACION_TYPES.PROJECT_DEVICES_EXPORTING]
          ? DeviceStrings.MENU_EXPORT_CSV_DOWNLOADING
          : DeviceStrings.MENU_EXPORT_CSV,
        onClick: () =>
          onAction(GLOBAL_ACION_TYPES.PROJECT_DEVICES_EXPORTING, true),
        disabled:
          !canReadDevices || // no permission
          actionType[GLOBAL_ACION_TYPES.PROJECT_DEVICES_EXPORTING] || // already exporting
          (actionType[GLOBAL_ACION_TYPES.PROJECT_DEVICES_LIVE] && !allLiveDevices) || // no live device to export
          (!actionType[GLOBAL_ACION_TYPES.PROJECT_DEVICES_LIVE] &&
            !noDecommissionDevice), // no decommission device to export
      },
    ];

    if (isSuperAdmin) {
      devicesOverflow.push({
        label: DeviceStrings.MENU_EXPORT_CSV_ADMIN,
        onClick: () =>
          onAction(GLOBAL_ACION_TYPES.PROJECT_DEVICES_EXPORTING_ADMIN, true),
      });
    }

    useEffect(() => {
      if (matchPath(location.pathname, { path: ROUTES.PROJECT_DEVICES })) {
        const key = DEVICE_SORT_OPTION_KEYS[0];
        initialSort(key, DEVICE_DESC_SORT_OPTIONS[key]);
        onClearAllFilter();
      }
      if (matchPath(location.pathname, { path: ROUTES.PROJECT_TEAM })) {
        const key = TEAM_SORT_OPTION_KEYS[0];
        initialSort(key, TEAM_DESC_SORT_OPTIONS[key]);
        onClearAllFilter();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    return {
      deleteProject,
      joinProject,
      leaveProject,
      transferProject,
      mediaEdit,
      mediaApprove,
      mediaShare,
      teamSortOptions,
      createQrDevice,
      createDisplayDevice,
      devicesSortOptions,
      devicesFilterOptions,
      devicesOverflow,
      projectTeamFilterOptions,
    };
  };
