import React, { useContext } from "react";

import { useProjectActions } from "./useProjectActions";
import { useProjectTabs } from "./useProjectTabs";
import {
  useUserProjectPermissions,
  useProjectTitle,
} from "services/ProjectService";
import { useTotalDevicesCount } from "services/DeviceService";
import { ROUTES } from "route";
import { CommentIcon, ShareIcon } from "assets/icons";
import { useMobileLayout } from "hooks/uiHooks";
import { TeamCodeStrings, ProjectStrings } from "strings";
import MainContext from "context/MainContext";
import { GLOBAL_ACION_TYPES } from "context/globalActionTypes";

export const useProjectConfig = ({ userId, projectId }) => {
  const mobile = useMobileLayout();
  const tabs = useProjectTabs(projectId);
  const title = useProjectTitle(projectId);

  const { onSearch, onAction } = useContext(MainContext);

  const {
    isSuperAdmin,
    isUserAdmin,
    canReadCode,
    canReadUsers,
    canWriteCode,
    canWriteAnalytics,
    canReadDevices,
  } = useUserProjectPermissions({
    userId,
    projectId,
  });
  const {
    leaveProject,
    deleteProject,
    joinProject,
    transferProject,
    mediaEdit,
    mediaApprove,
    mediaShare,
    teamSortOptions,
    createQrDevice,
    createDisplayDevice,
    devicesSortOptions,
    devicesFilterOptions,
    devicesOverflow,
    projectTeamFilterOptions,
  } = useProjectActions({
    userId,
    projectId,
  });
  const allDevices = useTotalDevicesCount(canReadDevices && projectId);

  // default menu items
  let overviewMenuItems = [leaveProject, deleteProject];

  // menu items for Admin in the organisation
  if (isUserAdmin) {
    overviewMenuItems = [joinProject, leaveProject, deleteProject];
  }
  // menu items for SuperAdmin
  if (isSuperAdmin) {
    overviewMenuItems = [leaveProject, transferProject, deleteProject];
  }
  let analyticsMenuItems;

  if (!mobile) {
    analyticsMenuItems = [];
    analyticsMenuItems.push({
      label: ProjectStrings.ANALYTICS_MENU_EDIT_ANALYTICS_CODE,
      onClick: () => onAction(GLOBAL_ACION_TYPES.PROJECT_ANALYTICS_CODE, true),
      disabled: !canWriteAnalytics,
    });
    // super admins
    if (isSuperAdmin) {
      analyticsMenuItems.push({
        label: ProjectStrings.ANALYTICS_MENU_EDIT_TRACKING_DETAILS,
        onClick: () =>
          onAction(GLOBAL_ACION_TYPES.PROJECT_ANALYTICS_TRACKING_DETAILS, true),
      });
    }
  }

  return [
    {
      path: ROUTES.PROJECT_OVERVIEW,
      appBar: {
        overflow: overviewMenuItems,
        tabDefault: "OVERVIEW",
        tabs,
        title,
      },
    },
    {
      path: ROUTES.PROJECT_MEDIA,
      appBar: {
        tabDefault: "MEDIA",
        tabs,
        title,
        extras: [
          {
            icon: <CommentIcon />,
            name: "Activity",
            onClick: () =>
              onAction(GLOBAL_ACION_TYPES.PROJECT_MEDIA_ACTIVITY, true),
          },
        ],
      },
      actionButton: {
        actions: mobile ? [mediaEdit] : [mediaEdit, mediaApprove, mediaShare],
      },
    },
    {
      path: [ROUTES.PROJECT_DEVICES, ROUTES.PROJECT_DEVICE_SINGLE],
      actionButton: {
        actions: [createQrDevice, createDisplayDevice],
      },
      appBar: {
        tabDefault: "DEVICES",
        tabs,
        onSearch: !canReadDevices || !allDevices ? null : onSearch,
        sort: !canReadDevices || !allDevices ? null : devicesSortOptions,
        filter: !canReadDevices || !allDevices ? null : devicesFilterOptions,
        overflow: devicesOverflow,
        title,
      },
    },
    {
      path: ROUTES.PROJECT_ANALYTICS,
      appBar: {
        tabDefault: "ANALYTICS",
        tabs,
        title,
        overflow: analyticsMenuItems,
      },
    },
    {
      path: ROUTES.PROJECT_TEAM,
      appBar: {
        tabDefault: "TEAM",
        tabs,
        onSearch:
          canReadUsers &&
          ((value) =>
            value && value.length > 1 ? onSearch(value) : onSearch(null)),
        sort: canReadUsers && teamSortOptions,
        filter: canReadUsers && projectTeamFilterOptions,
        overflow: [
          {
            label: TeamCodeStrings.TEAM_CODE_MANAGE,
            onClick: () =>
              onAction(GLOBAL_ACION_TYPES.PROJECT_TEAM_MANAGE, true),
            disabled: !canReadCode,
          },
        ],
        title,
      },
      actionButton: {
        primary: {
          icon: <ShareIcon />,
          name: TeamCodeStrings.TEAM_CODE_SHARE,
          onClick: () => onAction(GLOBAL_ACION_TYPES.PROJECT_TEAM_MANAGE, true),
          disabled: !canWriteCode,
        },
      },
    },
  ];
};
