import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useRouteMatch } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";

import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import Typography from "@material-ui/core/Typography";
import { useSnackbar } from "notistack";

import { useProjectInfo } from "hooks/projectHooks";
import {
  useUsersAwaitingApprovalCount,
  useUserProjectPermissions,
} from "services/ProjectService";
import { useProjectBasicPermissionsOptions } from "services/PermissionService";
import {
  generateProjectCode,
  joinApproveProject,
  joinRejectProject,
} from "services/ApiService";
import PullUpPanel from "../PullUpPanel";
import { useStyles } from "./style";
import { DefaultStrings, TeamCodeStrings, ProjectStrings } from "strings";
import MyDialog from "../MyDialog";
import { ShareCodes, Settings, AwaitingApporval } from "./components";
import { ROUTES, getProjectTeamShareTabRoute } from "route";
import { updateUrl } from "services/UiService";
import { useConfirm } from "hooks/uiHooks";
import { CheckIcon, DeleteIcon, LinkIcon, AddIcon } from "assets/icons";
import { Select, TextInput } from "components";

const URLS = ["share-code", "share-awaiting-approval", "share-settings"];

const TeamCodePanel = ({ projectId, userId, open, config }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const confirm = useConfirm();
  const matchManage = useRouteMatch([ROUTES.PROJECT_TEAM_SHARE_TAB]);
  const projectInfo = useProjectInfo(projectId);
  const permissionsOptions = useProjectBasicPermissionsOptions();
  const { canWriteCode, canReadCode, canReadProject, canWriteProject } =
    useUserProjectPermissions({
      userId,
      projectId,
    });

  const usersApprovalsCount = useUsersAwaitingApprovalCount(open && projectId);
  const defaultRole =
    permissionsOptions &&
    permissionsOptions[permissionsOptions.length - 1].value;

  const [showDialog, setShowDialog] = useState(false);
  const [codeName, setCodeName] = useState("");
  const [role, setRole] = useState(projectInfo?.defaultRole || defaultRole); // index as value
  const [progress, setProgress] = useState(false);
  const [currentTab, setCurrentTab] = useState(
    Math.max(0, URLS.indexOf(matchManage?.params?.tab))
  );

  useEffect(() => {
    if (projectInfo?.defaultRole) setRole(projectInfo?.defaultRole);
  }, [projectInfo]);

  const tabs = useMemo(
    () => [
      {
        url: URLS[0],
        label: TeamCodeStrings.TEAM_CODE_SHARE_CODE_TITLE,
        render: () => (
          <ShareCodes
            projectId={projectId}
            canEditCode={canWriteCode}
            canReadCode={canReadCode}
            onOpen={onOpenShareCode}
          />
        ),
      },
      {
        url: URLS[1],
        label: TeamCodeStrings.TEAM_CODE_AWIATING_APPROVAL_TITLE,
        render: () => (
          <AwaitingApporval
            projectId={projectId}
            canEditProject={canWriteProject}
          />
        ),
      },
      {
        url: URLS[2],
        label: TeamCodeStrings.TEAM_CODE_SETTINGS_TITLE,
        render: () => (
          <Settings
            info={projectInfo}
            canReadProject={canReadProject}
            canEditProject={canWriteProject}
          />
        ),
      },
    ],
    [
      projectId,
      canWriteCode,
      canReadCode,
      canWriteProject,
      projectInfo,
      canReadProject,
    ]
  );

  useEffect(() => {
    if (!open) {
      setCurrentTab(0);
    }
  }, [open]);

  const configDialog = {
    icon: <LinkIcon />,
    title: TeamCodeStrings.TEAM_CODE_SHARE,
    progress,
    disableOk: !codeName.length,
    onClose: () => {
      setShowDialog(false);
      setCodeName("");
      setRole(projectInfo?.defaultRole);
    },
    onOk: async () => {
      setProgress(true);
      try {
        await generateProjectCode({
          projectId,
          name: codeName,
          defaultRole: role || defaultRole,
        });

        enqueueSnackbar(TeamCodeStrings.TEAM_CODE_GENERATED, {
          variant: "success",
        });

        setShowDialog(false);
        setCodeName("");
        setRole(projectInfo?.defaultRole);
      } catch (err) {
        console.warn(err);
        enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
      } finally {
        setProgress(false);
      }
    },
  };

  const onOpenShareCode = () => {
    setShowDialog(true);
  };

  const onCodeNameChange = (e) => {
    setCodeName(e.target.value);
  };

  const onRoleChange = (e) => {
    setRole(e.target.value);
  };

  const onClickTab = useCallback(
    (_oldTab, newTab) => {
      setCurrentTab(newTab);
      updateUrl(getProjectTeamShareTabRoute(projectId, URLS[newTab]));
    },
    [projectId]
  );

  const menuAcceptAll = {
    label: TeamCodeStrings.TEAM_AWAITING_APPROVAL_APPROVE_ALL,
    onClick: () => {
      confirm({
        title: ProjectStrings.ADD_PROJECT_JOIN_APPROVE_ALL_CONFIRM_TITLE,
        message: ProjectStrings.ADD_PROJECT_JOIN_APPROVE_ALL_CONFIRM_DESC,
        config: {
          icon: <CheckIcon />,
          btOkColor: "primary",
          btOkTitle: DefaultStrings.BUTTON_APPROVE,
          cbAction: true,
        },
      })
        .then(async ({ onProgress, onClose }) => {
          onProgress(true);
          try {
            await joinApproveProject({ projectId });
            enqueueSnackbar(ProjectStrings.ADD_PROJECT_JOIN_APPROVED, {
              variant: "success",
            });
          } catch (err) {
            console.warn(err);
            enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
          } finally {
            onClose();
            onProgress(false);
          }
        })
        .catch(() => {})
        .finally(() => {});
    },
    disabled: !canWriteProject || !usersApprovalsCount,
  };

  const menuRejectAll = {
    label: TeamCodeStrings.TEAM_AWAITING_APPROVAL_REJECT_ALL,
    onClick: () => {
      confirm({
        title: ProjectStrings.ADD_PROJECT_JOIN_REJECT_ALL_CONFIRM_TITLE,
        message: ProjectStrings.ADD_PROJECT_JOIN_REJECT_ALL_CONFIRM_DESC,
        config: {
          icon: <DeleteIcon />,
          btOkColor: "secondary",
          btOkTitle: DefaultStrings.BUTTON_REJECT,
          cbAction: true,
        },
      })
        .then(async ({ onProgress, onClose }) => {
          onProgress(true);
          try {
            await joinRejectProject({ projectId });
            enqueueSnackbar(ProjectStrings.ADD_PROJECT_JOIN_REJECT, {
              variant: "success",
            });
          } catch (err) {
            console.warn(err);
            enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
          } finally {
            onClose();
            onProgress(false);
          }
        })
        .catch(() => {})
        .finally(() => {});
    },
    disabled: !canWriteProject || !usersApprovalsCount,
    color: "error",
  };

  const panelConfig = {
    ...config,
    tabs,
    currentTab,
    overflow: tabs[currentTab]?.url === tabs[1].url && [
      menuAcceptAll,
      menuRejectAll,
    ],
    actionContent: tabs[currentTab]?.url === tabs[0].url && (
      <IconButton
        color="inherit"
        onClick={onOpenShareCode}
        disabled={!canWriteCode}
      >
        <AddIcon />
      </IconButton>
    ),
    onClickTab,
  };

  return (
    <>
      <PullUpPanel open={open} config={panelConfig} />
      <MyDialog open={showDialog} config={configDialog}>
        <FormGroup>
          <Typography className={classes.shareCodeDescription}>
            {TeamCodeStrings.TEAM_CODE_SHARE_DESC}
          </Typography>
          <Box py={1}>
            <FormControl className={classes.formControl}>
              <TextInput
                label={TeamCodeStrings.TEAM_CODE_NAME}
                value={codeName}
                fullWidth
                onChange={onCodeNameChange}
              />
            </FormControl>
          </Box>
          {permissionsOptions && (
            <Box py={1}>
              <Select
                label={TeamCodeStrings.TEAM_CODE_DEFAULT_ROLE}
                selected={role || defaultRole}
                options={permissionsOptions}
                onChange={onRoleChange}
                fullWidth
              />
            </Box>
          )}
        </FormGroup>
      </MyDialog>
    </>
  );
};

export default TeamCodePanel;
