import React, { useState } from "react";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MuiSelect from "@material-ui/core/Select";
import MuiMenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";

import { useStyles, TextWrapper, MainText, AdditionalText } from "./style";
import { useBreakpoints } from "services/UiService";
import { SelectItem } from "./components";

/**
 * Select component: renders a Material-UI Select component with custom styling and features.
 * 
 * @param {Object} props - The props passed to the component, including:
 *   - name: The name of the select.
 *   - label: The label of the select.
 *   - selected: The currently selected value(s) of the select.
 *   - icon: The icon of the select.
 *   - fullWidth: A boolean indicating whether the select should take up the full width of its container.
 *   - highlightLast: A boolean indicating whether the last selected item should be highlighted.
 *   - options: An array of options for the select, where each option is an object containing:
 *     - value: The value of the option.
 *     - label: The label of the option.
 *   - onChange: The callback function to handle changes to the select.
 *   - disabled: A boolean indicating whether the select is disabled.
 *   - variant: The variant of the select (default: "select").
 *   - multiple: A boolean indicating whether multiple options can be selected (default: false).
 *   - onRenderValue: A function to render the selected value(s).
 */
export const Select = ({
  name,
  label,
  selected,
  icon,
  fullWidth,
  highlightLast,
  options,
  onChange,
  disabled,
  variant = "select",
  multiple = false,
  onRenderValue
}) => {
  const { isMobile } = useBreakpoints();
  const classes = useStyles({ isMobile, hasLabel: !!label });
  const [open, setOpen] = useState(false);

  // Handle close select  
  const closeHandle = () => {
    setOpen(false);
  };

  // Handle open select
  const openHandle = () => {
    setOpen(true);
  };

  return (
    <FormControl variant="filled" fullWidth={fullWidth}>
      {label && <InputLabel id={`select-${label}`}>{label}</InputLabel>}
      <MuiSelect
        labelId={`select-${label}`}
        className={classes.select}
        name={name}
        value={selected}
        open={open}
        onChange={onChange}
        onClose={closeHandle}
        onOpen={openHandle}
        disabled={disabled}
        multiple={multiple}
        renderValue={onRenderValue}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
      >
        {options.map((option, idx) => (
          <MuiMenuItem
            key={`option-${option.value}`}
            style={{
              color:
                highlightLast && options.length - 1 === idx
                  ? "#B23A48"
                  : "#323232",
            }}
            value={option.value}
            disabled={option?.disabled}
          >
            {variant === 'select' && (
              <SelectItem title={option.title} icon={icon} showBottomBorder={open && options.length - 1 !== idx} />
            )}
            {variant === 'checkbox' && (
              <>
                <Checkbox checked={selected.includes(option.title)} color="primary" />
                <TextWrapper>
                  <MainText primary={option.title} />
                  {option.additionalText && <AdditionalText primary={option.additionalText} />}
                </TextWrapper>
              </>
            )}
          </MuiMenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
};
