import React from "react";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import useStyles from "./styles";

/**
 * Accordion component for the filters drawer.
 * 
 * @param {Object} props - Component properties.
 * @param {string} props.title - Title of the accordion.
 * @param {ReactNode} props.icon - Icon of the accordion.
 * @param {boolean} props.defaultExpanded - Whether the accordion is expanded by default.
 * @param {ReactNode} props.children - Child components of the accordion.
 */
export const Accordion = (props) => {
  const { title, icon, defaultExpanded, expanded, children, onExpand } = props;
  
  const classes = useStyles();

  const onChangeHandler = (_event, newExpanded) => {
    onExpand(title, newExpanded);
  };

  return (
    <MuiAccordion
      classes={{ root: classes.accordion }}
      defaultExpanded={defaultExpanded}
      expanded={typeof expanded === 'undefined' ? defaultExpanded : expanded}
      onChange={onChangeHandler}
    >
      <MuiAccordionSummary
        id={`${title}-header`}
        classes={{ root: classes.accordionSummary }}
        aria-controls={`${title}-content`}
        expandIcon={<ExpandMoreIcon />}
      >
        {icon && <Box className={classes.filterIcon}>{icon}</Box>}
        <Typography variant="subtitle1">{title}</Typography>
      </MuiAccordionSummary>
      <MuiAccordionDetails classes={{ root: classes.accordionDetails }}>
        {children}
      </MuiAccordionDetails>
    </MuiAccordion>
  )
};