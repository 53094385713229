import React, { useState } from "react";
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { FilterIcon } from "assets/icons";
import { DefaultStrings } from "strings";
import useStyles from "./styles";
import { FilterItem, SortItem, Header, Accordion } from "./components";

/**
 * FiltersDrawer component for displaying filters and sorts.
 * 
 * @param {Object} props - Component properties.
 * @param {boolean} props.open - Whether the filters drawer is open.
 * @param {Array} props.filters - List of filter items.
 * @param {Array} props.sorts - List of sort items.
 * @param {number} props.selectedFiltersNumber - Number of selected filters.
 * @param {function} props.onToggle - Function to toggle the filters drawer.
 * @param {function} props.onFilterClear - Function to clear all filters.
 */
export const FiltersDrawer = (props) => {
  const { open, filters, sorts, selectedFiltersNumber, onToggle, onFilterClear } = props;

  const hasSort = sorts?.length > 0;
  const hasFilter = filters?.length > 0;

  const classes = useStyles();
  const [expanded, setExpanded] = useState({});

  // Handle toggle of the filters drawer
  const onToggleHandler = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    onToggle(open);
  };

  const onExpandHandler = (title, newExpanded) => {
    setExpanded({ ...expanded, [title]: newExpanded });
  };

  return (
    <SwipeableDrawer
      variant="temporary"
      anchor="right"
      open={open}
      onClose={onToggleHandler(false)}
      onOpen={onToggleHandler(true)}
      classes={{
        paper: classes.filterDrawer,
      }}
    >
      <Header
        filtersNumber={selectedFiltersNumber}
        onClickToggle={onToggleHandler(false)}
        onClickClear={onFilterClear}
      />
      <Box display="flex" flexDirection="column">
        {hasFilter && (
          <Box>
            <Box display="flex" alignItems="center">
              <FilterIcon className={classes.tuneIcon} />
              <Typography variant="h6">{DefaultStrings.BUTTON_FILTER}</Typography>
            </Box>
            {filters.map((filterItem) => (
              <Accordion key={filterItem.title}
                title={filterItem.title}
                icon={filterItem.icon}
                defaultExpanded={!!filterItem.defaultExpanded}
                expanded={expanded[filterItem.title]}
                onExpand={onExpandHandler}
              >
                <Box display="flex" alignItems="left" flexWrap="wrap" width="100%">
                  {filterItem.items && filterItem.items.map((item) => (
                    <FilterItem
                      key={item.title}
                      item={item}
                      items={filterItem.items}
                      selected={filterItem.selected}
                      defaultSelected={filterItem.defaultSelected}
                      onClick={filterItem.onClick}
                    />
                  ))}
                </Box>
              </Accordion>
            ))}
          </Box>
        )}
        {hasSort && (
          sorts.map((sortItem) => (
            <Accordion key={sortItem.title}
              title={sortItem.title}
              icon={sortItem.icon}
              defaultExpanded={!!sortItem.defaultExpanded}
              expanded={expanded[sortItem.title]}
              onExpand={onExpandHandler}
            >
              <Box display="flex" alignItems="left" flexDirection="column" width="100%">
                {sortItem.items.map((item) => (
                  <SortItem
                    key={item.label}
                    item={item}
                    selected={item.selected}
                    defaultSelected={sortItem.defaultSelected}
                    onClick={item.onClick}
                  /> 
                ))}
              </Box>
            </Accordion>
          ))
        )}
      </Box>
    </SwipeableDrawer>
  );
};
