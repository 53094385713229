import React, { useState, forwardRef, useImperativeHandle } from "react";
import MuiMenu from "@material-ui/core/Menu";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { useStyles } from "./styles";

/**
 * The Menu component is a forwardRef component that wraps the Material-UI Menu component.
 * It provides a custom implementation of the menu with additional features and styling.
 * 
 * @param {Object} props - The props passed to the component, including:
 *   - name: The name of the menu.
 *   - contentTitle: The title of the menu content.
 *   - content: The content of the menu.
 *   - title: The title of the menu.
 *   - icon: The icon of the menu.
 *   - posX: The horizontal position of the menu (default: "left").
 *   - posY: The vertical position of the menu (default: "bottom").
 * @param {React.RefObject} ref - The ref object passed to the component.
 * @returns {React.ReactElement} - The Menu component.
 */
export const Menu = forwardRef((props, ref) => {
  const { name, contentTitle, content, title, icon, posX = "left", posY = "bottom" } = props;
  
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // Handle open menu
  const onOpenHandle = (e) => setAnchorEl(e.currentTarget);
  // Handle close menu
  const onCloseHandle = () => setAnchorEl(null);
    
  // Handle close menu
  useImperativeHandle(ref, () => ({
    close: onCloseHandle
  }));

  return (
    <>
      <Button
        className={classes.iconButton}
        style={{ borderRadius: title ? "30px" : "50%", padding: title ? "10px 12px" : "12px" }}
        aria-controls={`${name.replace(/\s+/g, '_')}-menu`}
        aria-label={name}
        aria-haspopup="true"
        variant={title ? "contained" : "text"}
        onClick={onOpenHandle}
      >
        {icon}
        <Typography variant="caption" style={{
          lineHeight: "1.76",
          opacity: title ? 1 : 0,
          transition: "all 0.3s ease"
        }}>{title}</Typography>
      </Button>
      <MuiMenu
        id={`${name.replace(/\s+/g, '_')}-menu`}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        transformOrigin={{
          vertical: posY === "top" ? "bottom" : posY === "bottom" ? "top" : posY,
          horizontal: posX,
        }}
        anchorOrigin={{
          vertical: posY,
          horizontal: posX,
        }}
        open={open}
        onClose={onCloseHandle}
      >
        <div>
          {contentTitle && (
            <Box display="flex" alignItems="center">
              {contentTitle}
            </Box>
          )}
          {content}
        </div>
      </MuiMenu>
    </>
  );
});
