import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  toolbar: {
    minHeight: 48,
    height: "100%",
    alignItems: "center",
  },
  tabsWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
}));
