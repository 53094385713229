import { useEffect, useContext } from "react";

import { DEVICE_FILTER_OPTIONS } from "ui/pageLayout/config";
import { buildFilterItems } from "../../utils";
import { DefaultStrings } from "strings";
import MainContext from "context/MainContext";

export const useInitFilters = (devices) => {
  const { filterValue, onInintFilterItems, onFilter } = useContext(MainContext);
  
   /** Tags filter */
  const tags = buildFilterItems(devices, DEVICE_FILTER_OPTIONS.TAGS);
  const tagsItems = [...tags.values()];
  const tagsJointedStr = JSON.stringify(tagsItems);

  // /** Countries filter */
  const countries = buildFilterItems(devices, DEVICE_FILTER_OPTIONS.COUNTRY);
  const countriesItems = [...countries.values()];
  const countriesJointedStr = JSON.stringify(countriesItems);

  /** Reatails filter */
  const retailers = buildFilterItems(devices, DEVICE_FILTER_OPTIONS.RETAILER);
  const retailersItems = [...retailers.values()];
  const retailersJointedStr = JSON.stringify(retailersItems);

  useEffect(() => {
    if (tagsJointedStr) {
      if (tagsItems.length > 0 && filterValue.tags?.length) {
        const arr = [];
        filterValue.tags.forEach((tag) => {
          if (tags.has(tag)) {
            arr.push(tag);
          }
        });
        onFilter(DEVICE_FILTER_OPTIONS.TAGS, arr.length === 0 ? [DefaultStrings.FILTER_ALL] : arr);
      }
      onInintFilterItems(DEVICE_FILTER_OPTIONS.TAGS, tagsItems);
    }
    // eslint-disable-next-line
  }, [tagsJointedStr]);

  useEffect(() => {
    if (countriesJointedStr) {
      if (countriesItems.length > 0 && filterValue.country?.length) {
        const arr = [];
        filterValue.country.forEach((country) => {
          if (countries.has(country)) {
            arr.push(country);
          }
        });
        onFilter(DEVICE_FILTER_OPTIONS.COUNTRY, arr.length === 0 ? [DefaultStrings.FILTER_ALL] : arr);
      }
      onInintFilterItems(DEVICE_FILTER_OPTIONS.COUNTRY, countriesItems);
    }
    // eslint-disable-next-line
  }, [countriesJointedStr]);

  useEffect(() => {
    if (retailersJointedStr) {
      if (retailersItems.length > 0 && filterValue.retailerName?.length) {
        const arr = [];
        filterValue.retailerName.forEach((retailer) => {
          if (retailers.has(retailer)) {
            arr.push(retailer);
          }
        });
        onFilter(DEVICE_FILTER_OPTIONS.RETAILER, arr.length === 0 ? [DefaultStrings.FILTER_ALL] : arr);
      }

      onInintFilterItems(DEVICE_FILTER_OPTIONS.RETAILER, retailersItems);
    }
    // eslint-disable-next-line
  }, [retailersJointedStr]);

  
}