import React, { memo, useEffect, useState, useContext, useMemo } from "react";
import { useSnackbar } from "notistack";

import {
  useProjectDevices,
  useTotalLiveDevicesCount,
  useTotalDevicesCount,
} from "services/DeviceService";
import { debounce } from "services/UiService";
import { updateQRSetup } from "services/ProjectService";
import { uploadFile, deleteFile } from "services/StorageService";
import { isLoading } from "utils/uiUtils";
import {
  DEVICE_SORT_OPTION_FIELD,
  DEVICE_SORT_OPTION_KEYS,
  DEVICE_DESC_SORT_OPTIONS
} from "ui/pageLayout/config";
import { useMobileLayout } from "hooks/uiHooks";

import { EmptyData } from "ui/emptyData";
import Spinner from "ui/Spinner";

import DeviceTagsCard from "../../../cards/DeviceTagsCard";

import {  DeviceStrings } from "strings";
import { List } from "../index";
import { isDeviceListEqual } from "../../utils";
import { CustomQRCodeDialog } from "ui/dialogs";
import MainContext from "context/MainContext";
import { SelectContent, ActionsContent, TransferDialog } from "./components";
import { useInitFilters } from "./useInitFilters";
import { SearchIcon, DevicesIcon} from "assets/icons";
import { GLOBAL_ACION_TYPES } from "context/globalActionTypes";

const DISPLAY_ITEMS = {
  DEFAULT: 48,
  // MAX: 240,
  MAX: 100100100,
  LOAD_MORE: 24,
  MOBILE_DIVIDER: 6,
};

// TODO: this component has grown too big and complicated and needs a refactor
const DeviceList = memo(
  ({ projectId, live, permissions, onClickDevice, multiple }) => {
    const mobile = useMobileLayout();
    const { sortDescValue, sortFieldValue, sortDefaultFieldValue, searchValue, filterValue, onAction, onClearAllFilter } =
      useContext(MainContext);

    // params for data
    const itemDefault =
      DISPLAY_ITEMS.DEFAULT / (mobile ? DISPLAY_ITEMS.MOBILE_DIVIDER : 1);
    const [limit, setLimit] = useState(itemDefault);
    const sort =
      (sortFieldValue || sortDefaultFieldValue) && (sortFieldValue || sortDefaultFieldValue) in DEVICE_SORT_OPTION_FIELD
        ? DEVICE_SORT_OPTION_FIELD[sortFieldValue || sortDefaultFieldValue]
        : DEVICE_SORT_OPTION_FIELD[DEVICE_SORT_OPTION_KEYS[0]];
    const params = {
      limit,
      startAt: 0,
      orderBy: sort,
      orderDesc: (sortFieldValue || sortDefaultFieldValue) in sortDescValue ?
        DEVICE_SORT_OPTION_FIELD.SORT_OPTION_COMPLIANCE === sort ? !sortDescValue[sortFieldValue || sortDefaultFieldValue] : sortDescValue[sortFieldValue || sortDefaultFieldValue]
      : DEVICE_DESC_SORT_OPTIONS[sortFieldValue || sortDefaultFieldValue],
      filter: filterValue,
      search: searchValue,
      live,
    };

    // service data
    const devices = useProjectDevices({ projectId, params });
    const allDevices = useTotalDevicesCount(projectId);
    const liveDevices = useTotalLiveDevicesCount(projectId);
    const total = live ? liveDevices : allDevices - liveDevices;

    useInitFilters(devices);

    const [selectedDevices, setSelectedDevices] = useState({});
    const [selectedDevicePlatforms, setSelectedDevicePlatforms] = useState({});
    const [selectMultiple, setSelectMultiple] = useState(true);
    const [showTransferDialog, setShowTransferDialog] = useState(false);
    const [showTagDialog, setShowTagDialog] = useState(false);
    const [showCustomQRDialog, setShowCustomQRDialog] = useState(false);
    const [mediaQRSettings, setMediaQRSettings] = useState(null);

    const emptyText = useMemo(() => {
      if (total === 0) {
        return {
          title: live ? DeviceStrings.NO_DEVICES_TITLE : DeviceStrings.NO_DECOM_DEVICES_TITLE,
          description: DeviceStrings.NO_DEVICES_DESC,
          icon: <DevicesIcon />
        };
      }
      return {
        title: live ? DeviceStrings.NO_DEVICES_FOUND_TITLE : DeviceStrings.NO_DECOM_DEVICES_FOUND_TITLE,
        description: DeviceStrings.NO_DEVICES_FOUND_DESC,
        icon: <SearchIcon />
      };
    }, [total, live]);

    const actionsDisabled =
      Object.entries(selectedDevices).filter(([k, v]) => v).length === 0;
    const selectedDeviceIds = Object.entries(selectedDevices).map(
      ([k, v]) => k
    );
    const deviceIds = devices?.map((d) => d.deviceId);
    const canWriteDevices = permissions?.canWriteDevices;
    const canWriteDeviceExtra = permissions?.canWriteDeviceExtra;

    const deviceTransferred = selectedDeviceIds.some(
      (deviceId) => !deviceIds.includes(deviceId)
    );

    const { enqueueSnackbar } = useSnackbar();
    const selectedCount = selectedDeviceIds.length;

    const qrConfig = {
      downloadButtonTitle:
        DeviceStrings.CREATE_SMART_TAGS_DIALOG_DOWNLOAD_BUTTON_TITLE,
      urlTitle: DeviceStrings.CREATE_SMART_TAGS_DIALOG_URL_TITLE,
      selectedURLs: DeviceStrings.CREATE_SMART_TAGS_DIALOG_SELECTED_TITLE,
      downloadingTitle:
        DeviceStrings.CREATE_SMART_TAGS_DIALOG_DOWNLOADING_TITLE,
      downloadingDescription:
        DeviceStrings.CREATE_SMART_TAGS_DIALOG_DOWNLOADING_DESC,
    };

    useEffect(() => {
      const uniquePlatforms = Object.values(selectedDevices).reduce(
        (platforms, device) => {
          if (!platforms.includes(device.platform)) {
            platforms.push(device.platform);
          }
          return platforms;
        },
        []
      );
      setSelectedDevicePlatforms(uniquePlatforms);
    }, [selectedDevices]);

    // multiple is just a toggle trigger here
    useEffect(() => {
      setSelectMultiple(!selectMultiple);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [multiple]);

    // monitor device transferred
    useEffect(() => {
      if (deviceTransferred) {
        setSelectedDevices({});
        setSelectMultiple(false);
        setShowTransferDialog(false);
        enqueueSnackbar(DeviceStrings.TRANSFER_COMPLETED, {
          variant: "success",
        });
      }
    }, [deviceTransferred, enqueueSnackbar]);

    if (isLoading(devices) || isLoading(total)) return <Spinner />;

    const onLoadMore = debounce((size) => {
      if (size >= limit) {
        const max = mobile
          ? DISPLAY_ITEMS.MAX / DISPLAY_ITEMS.MOBILE_DIVIDER
          : DISPLAY_ITEMS.MAX;
        const newLimit = Math.min(
          limit +
            DISPLAY_ITEMS.LOAD_MORE /
              (mobile ? DISPLAY_ITEMS.MOBILE_DIVIDER : 1),
          total,
          max
        );
        if (newLimit > limit) {
          console.debug(`Showing ${newLimit} of ${total} devices`);
          setLimit(newLimit);
        }
      }
    }, 500);

    const onSelectDevice = (device) => {
      const { deviceId } = device;
      setSelectedDevices((prevState) => {
        const state = { ...prevState };
        if (state[deviceId]) {
          delete state[deviceId];
          return state;
        } else {
          return { ...prevState, [deviceId]: device };
        }
      });
    };

    const onSelectAllToggle = () => {
      if (selectedCount > 0) {
        onSelectNone();
      } else {
        onSelectAll();
      }
    };

    const onSelectAll = () => {
      setSelectedDevices(
        Object.fromEntries(devices.map((d) => [d.deviceId, d]))
      );
    };

    const onSelectNone = () => {
      setSelectedDevices({});
    };

    const onSelectCancel = () => {
      setSelectMultiple(false);
      onSelectNone();
    };

    const onOpenTagDialog = () => {
      setShowTagDialog(true);
    };

    const onOpenCustomQR = (qrSettings) => {
      setMediaQRSettings(qrSettings);
      setShowCustomQRDialog(true);
    };

    const onOkQRHandle = () => {
      setShowCustomQRDialog(false);
    };

    const onCloseQRHandle = () => {
      setShowCustomQRDialog(false);
      setMediaQRSettings(null);
    };

    const onUpdateQRHandle = async (qrSettings) => {
      const { qrSetup, image } = qrSettings;
      let data = {
        projectId,
        qrSetup,
      };

      const { file, action, ...restImage } = image;
      const imageStoragePath = `projects/${projectId}/devicesQRImage`;

      if (action === "upload") {
        const url = await uploadFile({ file, path: imageStoragePath });
        delete restImage.action;
        data = {
          ...data,
          qrSetup: { ...qrSetup, image: { ...restImage, url } },
        };
      } else if (action === "remove") {
        const { action, url, fileType, ...restImage } = image;
        await deleteFile({ path: imageStoragePath });
        data = { ...data, qrSetup: { ...qrSetup, image: { ...restImage } } };
      }

      await updateQRSetup(data);
      setMediaQRSettings(null);
    };

    const onOpenTransferDialog = () => {
      setShowTransferDialog(true);
    };
    const onCloseTransferDialog = () => {
      setShowTransferDialog(false);
    };

    if (total === 0 || !devices.length) {
      return <EmptyData
        title={emptyText.title}
        description={emptyText.description}
        actionTitle={live ? null : DeviceStrings.LIVE_DEVICES_BUTTON}
        onClick={live ? null : () => {
          onAction(GLOBAL_ACION_TYPES.PROJECT_DEVICES_LIVE, true);
          onClearAllFilter();
        }}
        icon={emptyText.icon}
      />
    }

    return (
      <>
         {selectMultiple && (
          <SelectContent
            selectedCount={selectedCount}
            onSelectAllToggle={onSelectAllToggle}
            onSelectCancel={onSelectCancel}
          />
        )}
        {selectMultiple && (
          <ActionsContent
            projectId={projectId}
            selectedDeviceIds={selectedDeviceIds}
            actionsDisabled={actionsDisabled}
            canWriteDevices={canWriteDevices}
            canWriteDeviceExtra={canWriteDeviceExtra}
            selectedDevicePlatforms={selectedDevicePlatforms}
            selectedCount={selectedCount}
            selectedDevices={selectedDevices}
            onOpenCustomQR={onOpenCustomQR}
            onOpenTagDialog={onOpenTagDialog}
            onOpenTransferDialog={onOpenTransferDialog}
          />
        )}
        <List
          devices={devices}
          max={total}
          onLoadMore={onLoadMore}
          onClickDevice={onClickDevice}
          onSelectDevice={onSelectDevice}
          selectedDevices={selectedDevices}
          multiple={selectMultiple}
        />
        {showTagDialog && (
          <DeviceTagsCard
            projectId={projectId}
            deviceId={selectedDeviceIds}
            canRead={permissions?.canReadDeviceExtra}
            canEdit={permissions?.canWriteDeviceExtra}
            variant={"no-card"}
            show={showTagDialog}
            setShow={setShowTagDialog}
          />
        )}
        <TransferDialog
          show={showTransferDialog}
          selectedDevices={selectedDevices}
          projectId={projectId}
          permissions={permissions}
          deviceTransferred={deviceTransferred}
          onClose={onCloseTransferDialog}
        />
        {mediaQRSettings && (
          <CustomQRCodeDialog
            open={showCustomQRDialog}
            title={DeviceStrings.CREATE_SMART_TAGS_DIALOG_TITLE}
            settings={mediaQRSettings}
            contentSettings={qrConfig}
            urlType="smartTag"
            folderName={`project-${projectId}-qr`}
            onOk={onOkQRHandle}
            onClose={onCloseQRHandle}
            onUpdate={onUpdateQRHandle}
          />
        )}
      </>
    );
  },
  isDeviceListEqual
);

export default DeviceList;
