import React, { useContext } from "react";

import { ROUTES } from "route";
import { ORG_TABS_KEYS } from "./config";
import {
  useOrganisationInfo,
  useUserOrganisationPermissions,
} from "services/OrganisationService";
import { useOrganisationActions } from "./useOrganisationActions";
import { useOrganisationTabs } from "./useOrganisationTabs";
import MainContext from "context/MainContext";
import { ShareIcon } from "assets/icons";
import { OrganisationStrings } from "strings";
import { GLOBAL_ACION_TYPES } from "context/globalActionTypes";

export const useOrganisationConfig = ({ userId, organisationId }) => {
  const { onSearch, onAction } = useContext(MainContext);

  const {
    canReadUsers,
    canReadProjects,
    canDeleteOrganisation,
    canWriteOrganisation,
    isSuperAdmin,
  } = useUserOrganisationPermissions({
    userId,
    organisationId,
  });

  const orgInfo = useOrganisationInfo(organisationId);
  const tabs = useOrganisationTabs(organisationId, isSuperAdmin);
  const {
    leaveOrganisation,
    deleteOrganisation,
    inviteToOrganisation,
    orgTeamSortOptions,
    orgGuestSortOptions,
    projectSortOptions,
    orgTeamFilterOptions,
  } = useOrganisationActions({
    organisationId,
    userId,
  });

  return [
    {
      path: ROUTES.ORGANISATION_DASHBOARD,
      appBar: {
        tabDefault: ORG_TABS_KEYS.DASHBOARD,
        overflow: canDeleteOrganisation
          ? [leaveOrganisation, deleteOrganisation]
          : [leaveOrganisation],
        tabs,
        title: orgInfo?.name,
      },
    },
    {
      path: ROUTES.ORGANISATION_PROJECTS,
      appBar: {
        tabDefault: ORG_TABS_KEYS.PROJECTS,
        tabs,
        onSearch:
          canReadProjects &&
          ((value) =>
            value && value.length > 1 ? onSearch(value) : onSearch(null)),
        sort: canReadProjects && projectSortOptions,
        title: orgInfo?.name,
      },
    },
    {
      organisationId,
      path: ROUTES.ORGANISATION_TEAM,
      appBar: {
        tabDefault: ORG_TABS_KEYS.TEAM,
        tabs,
        overflow: [inviteToOrganisation],
        onSearch:
          canReadUsers &&
          ((value) =>
            value && value.length > 1 ? onSearch(value) : onSearch(null)),
        sort: canReadUsers && orgTeamSortOptions,
        filter: canReadUsers && orgTeamFilterOptions,
        title: orgInfo?.name,
      },
      actionButton: {
        primary: {
          icon: <ShareIcon />,
          name: OrganisationStrings.INVITE_BUTTON_INFO,
          onClick: () => onAction(GLOBAL_ACION_TYPES.ORGANISATION_INVITE, true),
          disabled: !canWriteOrganisation,
        },
      },
    },
    {
      path: ROUTES.ORGANISATION_GUESTS,
      appBar: {
        tabDefault: ORG_TABS_KEYS.GUESTS,
        tabs,
        onSearch:
          canReadUsers &&
          ((value) =>
            value && value.length > 1 ? onSearch(value) : onSearch(null)),
        sort: canReadUsers && orgGuestSortOptions,
        title: orgInfo?.name,
      },
    },
    {
      path: ROUTES.ORGANISATION_ACTIVITY,
      appBar: {
        tabDefault: ORG_TABS_KEYS.ACTIVITY,
        tabs,
        title: orgInfo?.name,
      },
    },
    {
      path: ROUTES.ORGANISATION_SETTINGS,
      appBar: {
        tabDefault: ORG_TABS_KEYS.SETTINGS,
        tabs,
        title: orgInfo?.name,
      },
    },
    {
      path: ROUTES.ORGANISATION_JOIN,
      appBar: {},
    },
  ];
};
