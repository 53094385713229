import React, { useState, useRef, useEffect, useMemo, useContext } from "react";
import { connect } from "react-redux";

import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Badge from "@material-ui/core/Badge";

import { OverflowMenu } from "components";
import Search from "../Search";
import { TabBar, Title, OrgMenu, FiltersDrawer } from "./components";
import { useMobileLayout } from "hooks/uiHooks";
import { TuneIcon } from "assets/icons";
import { DefaultStrings } from "strings";
import { useStyles } from "./styles";
import MainContext from "context/MainContext";
/*
 sample config
 */
// const config = {
//   title: DefaultStrings.TITLE,
//   onSearch: (value) => {
//     console.debug("onSearch", value);
//   },
//   sort: [
//     {
//       label: "Most Recent",
//       onClick: () => {
//         console.debug("Most Recent");
//       },
//       selected: true,
//       desc: true,
//     },
//     {
//       label: "Name",
//       onClick: () => {
//         console.debug("Name");
//       },
//     },
//   ],
//   extras: [
//     {
//       icon: <EditIcon />,
//       name: "Edit",
//       onClick: () => {
//         console.debug("Edit");
//       },
//     },
//   ],
//   overflow: [
//     {
//       label: "Logout",
//       onClick: () => {
//         console.debug("Logout");
//       },
//       disabled: false,
//     },
//   ],
//   tabs: [
//     {
//       label: "Overview",
//       onClick: () => {
//         console.debug("Overview");
//       },
//     },
//     {
//       label: "Media",
//       onClick: () => {
//         console.debug("Media");
//       },
//     },
//   ],
// };

const mapStateToProps = ({
    firebase: {
        auth: { uid },
    },
}) => ({ userId: uid });

const MyAppBar = ({ config, path, userId, onFilterClear }) => {
  const mobile = useMobileLayout();
  const searchRef = useRef(null);
  const classes = useStyles();
  const { searchValue } = useContext(MainContext);

  const hasSearch = !!config?.onSearch;
  const hasOverflow = config?.overflow?.length > 0;
  const hasSort = config?.sort?.length > 0;
  const hasFilter = config?.filter?.length > 0;
  const hasTabs = config?.tabs?.length > 0;
  const hasExtra = config?.extras?.length > 0;
  const title = config?.title;
  const tabIndex = config?.tabs?.findIndex((t) => t.key === config?.tabDefault);
  const [searching, setSearching] = useState(false);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.clear();
    }
  }, [path]);

  useEffect(() => {
    if (searchValue === null && searchRef.current) {
      searchRef.current.clear();
    }
  }, [searchValue]);

  // Get unique selected filters number and sort options
  const selectedFiltersNumber = useMemo(() => {
    if (!hasFilter && !hasSort) return null;
    let selectedFilters = [];
    let selectedSort = [];

    if (hasFilter) {
      selectedFilters = [...new Set(config.filter.reduce((acc, action) =>
        acc.concat(
          (Array.isArray(action.selected) ? action.selected : [action.selected])
            .filter(filter => ![DefaultStrings.FILTER_ALL, DefaultStrings.FILTER_ALL.toLowerCase()].includes(filter))
        )
        , []))];
    }
    if (hasSort) {
      selectedSort = config.sort.reduce((acc, action) =>
        acc.concat(action.items.filter(item => item.selected))
        , []);
    }
 
    const allSelected = selectedFilters.concat(selectedSort);

    return allSelected.length > 0 ? allSelected.length : null;
  }, [config, hasFilter, hasSort]);

  const onTabChange = (event, newTab) => {
    if (config.tabs[newTab].onClick) {
      config.tabs[newTab].onClick(tabIndex, newTab);
    }
  };

  const onSearch = (value) => {
    setSearching(value !== null);
    if (config.onSearch) config.onSearch(value);
  };

  const onOpenFilterDrawerHandle = () => { 
    setOpenFilterDrawer(true)
  };

  const onToggleFilterDrawerHandle = (open) => {
    setOpenFilterDrawer(open);
  };

  return (
    <AppBar color="inherit" position="sticky">     
      <Toolbar className={classes.toolbar}>
        {/* Title */}
        {(!mobile || !searching) && <Title title={title} />}
        {/* Search */}
        {hasSearch && (
          <Search ref={searchRef} mobile={mobile} onSearch={onSearch} />
        )}
        {(hasFilter || hasSort) && (
          <IconButton
            aria-label="filters"
            color="inherit"
            onClick={onOpenFilterDrawerHandle}
          >
            <Badge color="primary" badgeContent={selectedFiltersNumber} invisible={!selectedFiltersNumber}>
              <TuneIcon />
            </Badge>
          </IconButton>
        )}
        {(!mobile || !searching) && (
          <>
            {/* Extra */}
            {hasExtra && (
              <>
                {config.extras.map((extra) => (
                  <IconButton
                    key={extra.name}
                    aria-label={extra.name}
                    color='inherit'
                    onClick={extra.onClick}
                    >
                    {extra.icon}
                  </IconButton>
                ))}
              </>
            )}
            {/* Overflow */}
            {hasOverflow && (
              <OverflowMenu
                name="my-app-bar"
                options={config.overflow}
                posX="left"
                posY="bottom"
              />
            )}
          </>
        )}
        {/* Organisations */}
        {userId && <OrgMenu userId={userId} path={path} />}
      </Toolbar>
      {hasTabs && (
        <div className={classes.tabsWrapper}>
          <TabBar
            mobile={mobile}
            currentTab={tabIndex}
            onTabChange={onTabChange}
            tabs={config.tabs}
          />
        </div>
      )}
      <FiltersDrawer
        open={openFilterDrawer}
        filters={config.filter}
        sorts={config.sort}
        selectedFiltersNumber={selectedFiltersNumber}
        onToggle={onToggleFilterDrawerHandle}
        onFilterClear={onFilterClear}
      />
    </AppBar>
  );
};

export default connect(mapStateToProps)(MyAppBar);
