import React, {
  useContext,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import PeopleIcon from "@material-ui/icons/People";

import { getProjectUsers } from "services/ProjectService";
import { useProjectUsersLastUpdate } from "services/UserService";
import { useMobileLayout } from "../hooks/uiHooks";
import {
  TEAM_SORT_OPTION_FIELD,
  TEAM_SORT_OPTION_KEYS,
  TEAM_DESC_SORT_OPTIONS,
  PROJECT_TEAM_FILTER_OPTIONS,
} from "ui/pageLayout/config";
import Spinner from "./Spinner";
import ProjectTeamMemberCard from "./cards/teamMemberCard/ProjectTeamMemberCard";
import { EmptyData } from "./emptyData";
import { ProjectStrings, DefaultStrings } from "strings";
import MainContext from "context/MainContext";
import InfiniteScroll from "ui/InfiniteScroll";

const LIMIT_DEFAULT = 20;

const useStyles = makeStyles((theme) => ({
  root: (mobile) => ({
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  }),
  cardContainer: (mobile) => ({
    padding: theme.spacing(1),
    width: mobile ? (window.innerWidth / 2) * 0.9 : 190,
    height: 300,
  }),
}));

const ProjectTeamList = ({
  config: { projectId, onClick, onOpenCode, progressId },
  canReadPermissions,
}) => {
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);

  const { sortDescValue, sortFieldValue, searchValue, filterValue } =
    useContext(MainContext);

  const [startAfter, setStartAfter] = useState();
  const [usersIds, setUsersIds] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [fetchedAt, setFetchedAt] = useState();
  const [isFetchedAll, setIsFetchedAll] = useState(false);
  const [isRequestSent, setIsRequestSent] = useState(false);

  const projectUsersLastUpdate = useProjectUsersLastUpdate();

  const resetData = () => {
    setIsFetchedAll(false);
    setIsRequestSent(true);
    setStartAfter();
  };

  const isSelectedFilter = useMemo(() => {
    const hasAnyFilter = Object.values(PROJECT_TEAM_FILTER_OPTIONS).some(
      (key) => filterValue[key] !== DefaultStrings.FILTER_ALL
    );
    return hasAnyFilter;
  }, [filterValue]);

  const userRoleFilter =
    filterValue[PROJECT_TEAM_FILTER_OPTIONS.ROLE] || DefaultStrings.FILTER_ALL;

  const getParams = (params) => {
    const { sortDesc, sortValue, lastKey, userRole } = params;
    const orderBy =
      sortValue && sortValue in TEAM_SORT_OPTION_FIELD
        ? TEAM_SORT_OPTION_FIELD[sortValue]
        : TEAM_SORT_OPTION_FIELD[TEAM_SORT_OPTION_KEYS[0]];

    return {
      limit: LIMIT_DEFAULT,
      orderBy,
      searchValue: params.searchValue,
      role: userRole,
      lastKey,
      orderDesc:
        sortValue in sortDesc
          ? [
              TEAM_SORT_OPTION_FIELD.SORT_OPTION_NAME,
              TEAM_SORT_OPTION_FIELD.SORT_OPTION_ROLE,
            ].includes(sortValue)
            ? !sortDesc[sortValue]
            : sortDesc[sortValue]
          : TEAM_DESC_SORT_OPTIONS[sortValue],
    };
  };

  const fetchData = useCallback(
    async (params) => {
      const requestParams = getParams({
        sortDesc: sortDescValue,
        sortValue: sortFieldValue,
        searchValue,
        userRole: userRoleFilter,
        ...params,
      });

      setLoading(true);

      try {
        const response = await getProjectUsers({
          projectId,
          ...requestParams,
        });
        const { items, fetchedAt: newFetchedAt } = response.result;

        setFetchedAt(newFetchedAt);

        if (items.length === 0 || items.length < LIMIT_DEFAULT) {
          setIsFetchedAll(true);
          setIsRequestSent(false);
        }

        if (params?.lastKey && !isRequestSent) {
          setUsersIds([...usersIds, ...items]);
        } else {
          setUsersIds(items);
          setIsRequestSent(false);
        }

        if (items.length) {
          setStartAfter(items[items.length - 1]);
        }

        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
        setLoadingMore(false);
      }
    },
    [
      isRequestSent,
      projectId,
      searchValue,
      sortDescValue,
      sortFieldValue,
      usersIds,
      userRoleFilter,
    ]
  );

  useEffect(() => {
    if (projectId) {
      // initial request on page loaded
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, sortFieldValue, sortDescValue, searchValue, userRoleFilter]);

  useEffect(() => {
    if (projectUsersLastUpdate > fetchedAt) {
      resetData();
      fetchData({ lastKey: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedAt, projectUsersLastUpdate]);

  const configScroll = {
    onLoadMore: async () => {
      if (isFetchedAll || !startAfter || loading) return;

      setLoadingMore(true);
      await fetchData({ lastKey: startAfter }, true);
      setLoadingMore(false);
    },
  };

  if (typeof usersIds === "undefined" || (loading && !loadingMore))
    return <Spinner />;

  if (!usersIds.length)
    return (
      <EmptyData
        title={
          searchValue || isSelectedFilter
            ? ProjectStrings.USERS_NO_FOR_SEARCH
            : ProjectStrings.USERS_NO_IN_PROJECT_TITLE
        }
        description={
          searchValue || isSelectedFilter
            ? null
            : ProjectStrings.USERS_NO_IN_PROJECT_DESC
        }
        actionTitle={
          searchValue || isSelectedFilter
            ? null
            : ProjectStrings.USERS_NO_IN_PROJECT_ACTION_TITLE
        }
        onClick={onOpenCode}
        icon={<PeopleIcon />}
      />
    );

  return (
    <div className={classes.root}>
      <InfiniteScroll config={configScroll} size={usersIds.length}>
        {usersIds.map((userId) => (
          <div key={userId} className={classes.cardContainer}>
            <ProjectTeamMemberCard
              userId={userId}
              projectId={projectId}
              canReadPermissions={canReadPermissions}
              progress={progressId && progressId === userId}
              onClick={onClick}
            />
          </div>
        ))}
      </InfiniteScroll>
      {loadingMore && usersIds?.length !== 0 && (
        <div style={{ position: "relative", width: "100%", height: "80px" }}>
          <Spinner size={30} />
        </div>
      )}
    </div>
  );
};

export default ProjectTeamList;
