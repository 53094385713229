import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

// disable logging for non-development builds
if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
  console.debug = () => {};
  console.warn = () => {};
  console.error = () => {};
  console.info = () => {};
}

ReactDOM.render(<App />, document.getElementById("root"));

if (process.env.NODE_ENV === "development" && process.env.REACT_APP_VERSION)
  console.info(`Version=${process.env.REACT_APP_VERSION}`);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
