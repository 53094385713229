import React, { useEffect } from "react";
import { useParams, useHistory, generatePath } from "react-router-dom";
import { useSnackbar } from "notistack";

import { useUserInOrganisation } from "services/OrganisationService";
import {
  useMostRecentOrganisationsIds,
  useCurrentOrganisation,
  useSystemAdmin,
} from "services/UserService";
import { getProjectsRoute } from "route";
import { OrganisationStrings } from "strings";

export const withInOrganisationCheck = (BaseComponent) => {
  // HOC logic using hooks
  return function (props) {
    const { userId, match } = props;
    const params = useParams();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const currentOrgId = params.organisationId;

    const isSuperAdmin = useSystemAdmin(userId);

    const orgIds = useMostRecentOrganisationsIds();
    const selectedOrganisationId = useCurrentOrganisation();

    const userInOrganisation = useUserInOrganisation({
      organisationId: isSuperAdmin ? currentOrgId : selectedOrganisationId,
      userId,
    });

    useEffect(() => {
      // if the user is not a super admin and is not in the organisation
      if (isSuperAdmin === false && (userInOrganisation === null || userInOrganisation)) {
        if (selectedOrganisationId === null) {
          // no organisation selected
          enqueueSnackbar(OrganisationStrings.NO_PART_OF_ANY_ORGANISATION, {
            variant: "warning",
          });
          history.push(getProjectsRoute());
        } else if (selectedOrganisationId !== currentOrgId) {
          // update the organisationId in the url
          const path = generatePath(match.path, {
            ...params,
            organisationId: selectedOrganisationId,
          });
          history.push(path);
        }
      }
    }, [
      enqueueSnackbar,
      history,
      match,
      orgIds,
      currentOrgId,
      params,
      selectedOrganisationId,
      userInOrganisation,
      isSuperAdmin,
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps

    return <BaseComponent {...props} />;
  };
};
