import React, { useEffect, useState, useCallback, useContext } from "react";
import ViewCarouselOutlinedIcon from "@material-ui/icons/ViewCarouselOutlined";
import SearchIcon from "@material-ui/icons/Search";

import { getAllProjects } from "services/ApiService";
import { useMobileLayout } from "hooks/uiHooks";
import { useStyles } from "./style";
import { ProjectStrings, DefaultStrings } from "strings";
import Spinner from "ui/Spinner";
import ProjectCard from "ui/cards/projectCard/ProjectCard";
import InfiniteScroll from "ui/InfiniteScroll";
import { EmptyData } from "ui/emptyData";
import { useProjectUsersLastUpdate } from "services/UserService";
import MainContext from "context/MainContext";
import {
  PROJECT_SORT_OPTION_FIELD,
  PROJECT_SORT_OPTION_KEYS,
  PROJECT_DESC_SORT_OPTIONS,
  ADMIN_PROJECTS_FILTER_OPTIONS,
  BELONGING_TO_KEYS,
} from "ui/pageLayout/config";

const Projects = () => {
  const [startAfter, setStartAfter] = useState();
  const [projectsIds, setProjectsIds] = useState();
  const [isFetchedAll, setIsFetchedAll] = useState(false);
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [fetchedAt, setFetchedAt] = useState();

  const projectUsersLastUpdate = useProjectUsersLastUpdate();
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  const { sortFieldValue, sortDescValue, searchValue, filterValue } =
    useContext(MainContext);

  const mobile = useMobileLayout();
  const classes = useStyles({ mobile, small: false });
  const belongingToOrg = filterValue[ADMIN_PROJECTS_FILTER_OPTIONS.BELONGING_TO_ORG] || BELONGING_TO_KEYS.ALL;
  const projectStatus = filterValue[ADMIN_PROJECTS_FILTER_OPTIONS.STATUS] || DefaultStrings.FILTER_ALL;

  const resetData = () => {
    setIsFetchedAll(false);
    setIsRequestSent(true);
    setStartAfter();
  };

  const getParams = useCallback((params) => {
    const {
      sortDesc,
      sortValue,
      lastKey,
      belongingToOrg: belongingTo,
      status,
    } = params;
    const orderBy =
      sortValue && sortValue in PROJECT_SORT_OPTION_FIELD
        ? PROJECT_SORT_OPTION_FIELD[params.sortValue]
        : PROJECT_SORT_OPTION_FIELD[PROJECT_SORT_OPTION_KEYS[0]];

    return {
      limit: 20,
      orderBy,
      searchValue: params.searchValue,
      lastKey,
      orderDesc: sortValue in sortDesc
        ? sortDesc[sortValue]
        : PROJECT_DESC_SORT_OPTIONS[sortValue],
      belongingToOrg: belongingTo,
      status,
    };
  }, []);

  const fetchData = useCallback(
    async (params) => {
      const requestParams = getParams({
        belongingToOrg,
        status: projectStatus,
        sortDesc: sortDescValue,
        sortValue: sortFieldValue,
        searchValue,
        ...params,
      });

      setLoading(true);

      try {
        const response = await getAllProjects(requestParams);
        const items = response.result.items;

        setFetchedAt(response.result.fetchedAt);

        if (items.length === 0) {
          setIsFetchedAll(true);
          setIsRequestSent(false);
        }

        if (params?.lastKey && !isRequestSent) {
          setProjectsIds([...projectsIds, ...items]);
        } else {
          setProjectsIds(items);
          setIsRequestSent(false);
        }

        if (items.length) {
          setStartAfter(items[items.length - 1]);
        }
    
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setLoadingMore(false);
        console.log(err);
      }
    },
    [
      belongingToOrg,
      getParams,
      isRequestSent,
      projectsIds,
      searchValue,
      sortDescValue,
      sortFieldValue,
      projectStatus,
    ]
  );

  useEffect(() => {
    // initial request on page loaded
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortFieldValue, sortDescValue, searchValue, belongingToOrg, projectStatus]);

  useEffect(() => {
    // do request if project users last update date changed
    if (projectUsersLastUpdate > fetchedAt) {
      resetData();
      fetchData({ lastKey: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectUsersLastUpdate, fetchedAt]);

  const configCard = {
    showParticipants: true,
    showOrgName: true,
    clickToOpen: true,
  };

  const config = {
    onLoadMore: async() => {
      if (isFetchedAll || !startAfter || loading) return;

      setLoadingMore(true);
      await fetchData({ lastKey: startAfter });
      setLoadingMore(false);
    },
  };

  const renderContent = () => {
    if (typeof projectsIds === "undefined" || (loading && !loadingMore)) return <Spinner />;

    if (!projectsIds.length && !isRequestSent)
      return (
        <EmptyData
          title={
            searchValue || projectStatus !== DefaultStrings.FILTER_ALL
              ? ProjectStrings.LIST_NO_PROJECTS
              : ProjectStrings.LIST_NO_FOR_SEARCH
          }
          icon={searchValue || projectStatus !== DefaultStrings.FILTER_ALL ? <ViewCarouselOutlinedIcon /> : <SearchIcon />}
        />
      );

    return (
      <InfiniteScroll config={config} size={projectsIds.length}>
        <div className={classes.main}>
          {projectsIds.map((projectId) => (
            <div key={projectId} className={classes.cardContainer}>
              <ProjectCard projectId={projectId} config={configCard} />
            </div>
          ))}
        </div>
      </InfiniteScroll>
    );
  };

  return (
    <div className={classes.root}>
      {loading && <Spinner />}
      {renderContent()}
      {loadingMore && projectsIds?.length !== 0 && (
        <div style={{ position: "relative", width: "100%", height: "80px" }}>
          <Spinner size={30} />
        </div>
      )}
    </div>
  );
};

export default Projects;
