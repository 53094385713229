import React, { useState, useMemo, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./style";
import { useSnackbar } from "notistack";

import ProfileDialog from "ui/dialogs/ProfileDialog";
import { DefaultStrings, TeamCodeStrings } from "strings";
import { useMobileLayout, useConfirm } from "hooks/uiHooks";
import { userIsAdmin } from "services/AuthService";
import { Select } from "components";
import { UserRemoveIcon } from "assets/icons";

export const TeamMemberDialog = (props) => {
  const {
    targetUser,
    targetUserPermissions,
    userId,
    userPermissions,
    permissionNames,
    confirmTitle,
    confirmDesc,
    onProgressId,
    onClose,
    onRemoveUser,
    onUpdateUserRole,
  } = props;
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  const { canDeleteUsers, canWriteUserPermissions, canReadUserPermissions } =
    userPermissions;

  const [newPermissionName, setNewPermissionName] = useState(
    targetUser?.permissionsKey
  );
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    userIsAdmin().then((res) => setIsSuperAdmin(res));
  }, []);

  useEffect(() => {
    if (!isEditing && targetUser?.permissionsKey) {
      const permissionKey = permissionNames.find(
        (item) => targetUser?.permissionsKey === item.value
      );
      setNewPermissionName(permissionKey?.value);
    }
  }, [isEditing, permissionNames, targetUser]);

  const isSelectedSelf = useMemo(
    () => targetUser && userId === targetUser?.userId,
    [userId, targetUser]
  );

  const selectedAdmin = useMemo(
    () => targetUserPermissions.isUserAdmin,
    [targetUserPermissions]
  );

  const canDeleteUserSelected = useMemo(
    () => isSuperAdmin || (!isSelectedSelf && canDeleteUsers && !selectedAdmin),
    [canDeleteUsers, isSelectedSelf, isSuperAdmin, selectedAdmin]
  );

  const permissionsOptions = useMemo(() => {
    if (!permissionNames) return null;

    let perms = permissionNames;

    if (targetUser?.userName) {
      perms = [
        ...perms,
        {
          title: `${DefaultStrings.BUTTON_REMOVE} ${targetUser?.userName}`,
          value: "remove-user",
          disabled: !canDeleteUserSelected,
        },
      ];
    }
    return perms;
  }, [canDeleteUserSelected, permissionNames, targetUser]);
  

  const canEditPermissionsSelected = useMemo(
    () =>
      isSuperAdmin ||
      (!isSelectedSelf && canWriteUserPermissions && !selectedAdmin),
    [canWriteUserPermissions, isSelectedSelf, isSuperAdmin, selectedAdmin]
  );

  const config = {
    showButtonBar: true,
    disableOk:
      newPermissionName && targetUser?.permissionsKey === newPermissionName,
    onClose: () => onClose(),
    onOk: async () => {
      onProgressId(targetUser.userId);
      try {
        await onUpdateUserRole(newPermissionName);
        setIsEditing(false);
        enqueueSnackbar(TeamCodeStrings.UPDATE_TEAM_MEMBER_ROLE, {
          variant: "success",
        });
      } catch (err) {
        enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
        console.warn(err);
      } finally {
        onProgressId(null);
        onClose();
      }
    },
  };

  const permissionChangeHandle = (event) => {
    if (event.target.value === "remove-user") {
      confirm({
        title: confirmTitle.replace("{name}", targetUser.userName),
        message: confirmDesc.replace("{name}", targetUser.userName),
        config: {
          icon: <UserRemoveIcon />,
          btOkColor: "secondary",
          btOkTitle: DefaultStrings.BUTTON_REMOVE,
          cbAction: true,
        },
      })
        .then(async ({ onProgress, onClose: onConfirmClose }) => {
          onProgress(true);
          try {
            await onRemoveUser(targetUser.userId);

            enqueueSnackbar(TeamCodeStrings.TEAM_MEMBER_REMOVED, {
              variant: "success",
            });
          } catch (err) {
            enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
            console.warn(err);
          } finally {
            onProgress(false);
            onConfirmClose();
            onClose();
          }
        })
        .catch(() => {})
        .finally(() => {
          onClose();
        });
    } else {
      setIsEditing(true);
      setNewPermissionName(event.target.value);
    }
  };

  return (
    <ProfileDialog
      userImage={targetUser?.userImage}
      open={!!targetUser}
      config={config}
      titleChildren={
        targetUser?.userNameLetters && (
          <Avatar className={classes.emptyMedia} variant="square">
            {targetUser.userNameLetters}
          </Avatar>
        )
      }
    >
      <Box pt={2}>
        <Typography className={classes.name}>{targetUser?.userName}</Typography>
        <Typography className={classes.email}>
          {targetUser?.userEmail}
        </Typography>
        {canReadUserPermissions && (
          <>
            {canEditPermissionsSelected &&
            newPermissionName &&
            permissionsOptions ? (
              <Select
                options={permissionsOptions}
                selected={newPermissionName}
                onChange={permissionChangeHandle}
                fullWidth
                highlightLast
              />
            ) : (
              <div className={classes.permission}>
                {targetUser?.permissionName}
              </div>
            )}
          </>
        )}
      </Box>
    </ProfileDialog>
  );
};
