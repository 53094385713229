import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

import { CloseIcon, RefreshIcon } from "assets/icons";
import { DefaultStrings } from "strings";
import useStyles from "./styles";

/**
 * Header component for the filters drawer.
 * 
 * @param {Object} props - Component properties.
 * @param {number} props.filtersNumber - Number of selected filters.
 * @param {function} props.onClickToggle - Function to toggle the filters drawer.
 * @param {function} props.onClickClear - Function to clear all filters.
 */
export const Header = (props) => {
  const { filtersNumber, onClickToggle, onClickClear } = props;

  const classes = useStyles();

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <IconButton
        aria-label={DefaultStrings.BUTTON_CLOSE}
        color="inherit"
        onClick={onClickToggle}
        >
          <CloseIcon />
      </IconButton>
      <Button
        className={classes.clearButton}
        variant="text"
        color="primary"
        size="small"
        startIcon={<RefreshIcon />}
        disabled={!filtersNumber}
        onClick={onClickClear}
      >
        {DefaultStrings.BUTTON_CLEAR_ALL}
      </Button>
    </Box>
  )
};