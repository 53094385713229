import { API_PATHS, postPrivateRequest } from "./ApiService";

export const getProjectUsersApi = ({
  projectId,
  limit,
  orderBy,
  orderDesc,
  searchValue,
  role,
  lastKey,
}) => {
  const path = API_PATHS.PROJECT_USERS_LIST;

  const data = {
    projectId,
    limit,
    orderBy,
    orderDesc,
    searchValue,
    role,
    lastKey,
  };

  console.debug("getProjectUsersApi", path, data);
  return postPrivateRequest({ path, data });
};
