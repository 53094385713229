import React from "react";

import MyDialog from "ui/MyDialog";
import DeviceTransfer from "ui/DeviceTransfer";

export const TransferDialog = (props) => {
  const { show, selectedDevices, projectId, permissions, deviceTransferred, onClose } = props;

  const configDialog = {
    onClose,
    large: true,
  };

  return (
    <MyDialog open={show} config={configDialog}>
      <div
        style={{
          height: 600, // in order to stretch MyDialog
        }}
      >
        <DeviceTransfer
          devices={selectedDevices}
          projectId={projectId}
          permissions={permissions}
          active={true}
          dismiss={deviceTransferred}
        />
      </div>
    </MyDialog>
  )
};
