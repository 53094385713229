import React, { memo, useMemo } from "react";
import LinePlot from "components/LineChart/LinePlot";

const HeartBeatLineChart = ({
  chartHeatsBeatsData,
  xAxisLabel,
  yAxisLabel,
  formatLabel, // formatLabel = (value, date) => return [value, date]
}) => {
  const datesLabels = useMemo(() => {
    return chartHeatsBeatsData?.map((item, index) => {
      if (index === 0) return [item.date, "-90"];
      else if (
        index ===
        Math.floor(
          (chartHeatsBeatsData.map((item) => item.date).length - 1) / 2
        )
      )
        return [item.date, "-45"];
      else if (
        index ===
        chartHeatsBeatsData.map((item) => item.date).length - 1
      )
        return [item.date, "-1"];
      else return [item.date, ""];
    });
  }, [chartHeatsBeatsData]);

  const options = {
    animation: true,
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: "none",
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return formatLabel(
              context.formattedValue,
              datesLabels[context.dataIndex][0]
            );
          },
          labelColor: (context) => {
            return {
              borderColor: "white",
              backgroundColor: "#F78130",
            };
          },
          labelTextColor: () => {
            return "white";
          },
          title: function () {},
        },
        backgroundColor: "gray",
        bodyFontSize: 14,
        displayColors: false,
      },
    },
    scales: {
      x: {
        ticks: {
          display: true,
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
        },
        title: {
          display: true,
          text: xAxisLabel,
          color: "#000000",
          align: "center",
        },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          display: false,
        },
        title: {
          display: true,
          text: yAxisLabel,
          color: "#000000",
          align: "center",
        },
        grid: {
          display: false,
        },
        min: 0,
      },
    },
  };

  const chartData = useMemo(
    () => ({
      labels: datesLabels && datesLabels.map((item) => item[1]),
      datasets: [
        {
          data: chartHeatsBeatsData?.map((item) => item.value),
          // smooth out the line
          lineTension: 0.1,
          // line width
          borderWidth: 2,
          // line color
          borderColor: "#F78130",
          // solid-data points
          pointBackgroundColor: "#F78130",
          // data points onHover
          pointHoverBackgroundColor: "#F78130;",
          pointHoverBorderColor: "#F78130;",
        },
      ],
    }),
    [chartHeatsBeatsData, datesLabels]
  );

  return <LinePlot data={chartData} options={options} redraw="false" />;
};

export const MemoizedChart = memo(HeartBeatLineChart);
