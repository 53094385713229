import React, { useState } from "react";

import StatCard from "./statCard/StatCard";
import { useOrganisationDevicesStatistics, useOrganisationDevicesOnlineState } from "services/OrganisationService";
import { DEVICE_PLATFORMS } from "services/DeviceService";
import { OrganisationStrings } from "strings";
import { DeviceIcon } from "assets/icons";

const OrganisationDeviceStatCard = ({ organisationId, canRead, canEdit }) => {
  const [isDoAction, setIsDoAction] = useState(false);

  const stats = useOrganisationDevicesStatistics(organisationId);
  const platforms = Object.values(DEVICE_PLATFORMS).filter(
    (platform) => platform !== DEVICE_PLATFORMS.QRID
  );

  const onlineStates = useOrganisationDevicesOnlineState(
    isDoAction && organisationId,
    90,
    platforms
  );

  const onActionHandle = () => {
    setIsDoAction(!isDoAction);
  };

  return (
    <StatCard
      icon={<DeviceIcon />}
      title={OrganisationStrings.OVERVIEW_DEVICES_STAT_TITLE}
      loading={typeof stats === "undefined"}
      live={stats?.live || 0}
      online={stats?.online || 0}
      compliant={stats?.compliant || 0}
      charts={onlineStates}
      canRead={canRead}
      canEdit={canEdit}
      onAction={onActionHandle}

    />
  );
};

export default OrganisationDeviceStatCard;
