import { makeStyles, styled } from "@material-ui/core/styles";
import { ListItemText } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  select: ({ isMobile, hasLabel }) => ({
    minWidth: 150,
    width: isMobile ? "100%" : "auto",
    "& .MuiFilledInput-input": {
      padding: hasLabel ? "24px 12px 12px" : "12px",
    },
    "&.MuiFilledInput-underline": {
      "&:before": {
        borderBottom: "none",
      },
      "&:after": {
        borderBottom: "none",
      },
      "&.Mui-disabled": {
        backgroundColor: "#F4F4F4",
        "&:before": {
          border: "none",
        },
      },
      "&:hover:before": {
        border: "none",
      },
    },
  }),
  menuItem: {
    color: "#323232",
    // paddingTop: theme.spacing(1.5),
  },
}));

export const TextWrapper = styled("div")({
  "&": {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    gap: "32px",
  },
});

export const MainText = styled(ListItemText)({
  "&": {
    flex: "unset",
  },
});

export const AdditionalText = styled(ListItemText)({
  "&": {
    flex: "unset",
    "& > span": {
      fontFamily: "myplayer-monospace",
    },
  },
});
