import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';

import { ArrowDownIcon, ArrowUpIcon } from "assets/icons";
import useStyles from "./styles";

/**
 * Sort item component for the filters drawer.
 * 
 * @param {Object} props - Component properties.
 * @param {Object} props.item - Sort item data.
 * @param {boolean} props.selected - Whether the sort item is selected.
 * @param {string} props.defaultSelected - Default selected sort value.
 * @param {function} props.onClick - Function to handle sort click.
 */
export const SortItem = ({ item, selected, defaultSelected, onClick }) => {
  const classes = useStyles();
  
  // Handle click on sort
  const onClickHandle = () => {
    if (onClick) onClick();
  };

  return (
    <Box p={0.5}>
      <Button
        classes={{root: clsx(classes.filterButton, selected && classes.selectedFilterButton)}}
        variant="outlined"
        color="primary"
        style={{ justifyContent: "space-between" }}
        endIcon={
          (selected ||
            (selected === null && item.value === defaultSelected)) ?
            (item.desc ? <ArrowDownIcon /> : <ArrowUpIcon />) : null
        }
        onClick={onClickHandle}
      >
        <div className={classes.filterButtonLabel}>{item.label}</div>
      </Button>
    </Box>
  )
};
